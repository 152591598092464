import { Outlet, useLocation } from "react-router";

import {
  AnimatedContainer,
  Breadcrumbs,
  PageTitle,
  TabPanel,
  Tabs,
} from "@/shared/components";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";

export function PreventiveConfigLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("customization");

  const tabs = useAuthorizedTabs([
    {
      id: `${baseUrl}revisao/`,
      href: `${baseUrl}revisao/`,
      label: "Configurações de revisão",
    },
  ]);

  return (
    <AnimatedContainer className="mt-0 flex h-full w-[calc(100vw-240px)] flex-col space-y-6 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle primary>Customizações da assistência</PageTitle>
      </div>
      <Tabs
        aria-label="Preventivos do sistema"
        tabs={tabs}
        selectedKey={pathname}
        className="rounded-none rounded-t-lg"
      >
        <TabPanel className="mt-0" id={pathname}>
          <Outlet />
        </TabPanel>
      </Tabs>
    </AnimatedContainer>
  );
}
