import { Outlet, useLocation } from "react-router";

import {
  AnimatedContainer,
  QueryHandler,
  TabPanel,
  Tabs,
} from "@/shared/components";
import {
  useAuthorizedTabs,
  useRequiredParams,
  useTabBaseUrl,
} from "@/shared/hooks";
import { useMaintenance } from "@/shared/hooks/api";
import {
  AssistanceStatus,
  MaintenanceStatusId,
  MaintenanceTypeId,
} from "@/shared/utils";

import {
  MaintenanceDetailError,
  MaintenanceDetailLoading,
} from "../components";
import { CallDetailData } from "../containers";

export function CallDetailLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("service");
  const { serviceId } = useRequiredParams();

  const maintenanceQuery = useMaintenance(serviceId);
  const maintenanceStatus = maintenanceQuery.data?.statusManutencao;

  const tabs = useAuthorizedTabs(
    [
      {
        id: `${baseUrl}geral/`,
        href: `${baseUrl}geral/`,
        label: "Geral",
      },
      {
        id: `${baseUrl}custos/`,
        href: `${baseUrl}custos/`,
        label: "Custos",
        shouldRender:
          maintenanceStatus?.id !== AssistanceStatus.UNDER_REVIEW &&
          maintenanceStatus?.id !== MaintenanceStatusId.SOLICITED,
      },
      {
        id: `${baseUrl}arquivos-e-midias/`,
        href: `${baseUrl}arquivos-e-midias/`,
        label: "Arquivos e mídias",
      },
      {
        id: `${baseUrl}diagnostico/`,
        href: `${baseUrl}diagnostico/`,
        label: "Diagnóstico",
        shouldRender:
          maintenanceStatus?.id !== AssistanceStatus.UNDER_REVIEW &&
          maintenanceStatus?.id !== MaintenanceStatusId.SOLICITED &&
          maintenanceStatus?.tipoManutencaoId !== MaintenanceTypeId.PREVENTIVE,
      },
      {
        id: `${baseUrl}comentarios/`,
        href: `${baseUrl}comentarios/`,
        label: "Comentários",
      },
      {
        id: `${baseUrl}agendamentos/`,
        href: `${baseUrl}agendamentos/`,
        label: "Agendamentos",
        shouldRender:
          maintenanceStatus?.id !== AssistanceStatus.UNDER_REVIEW &&
          maintenanceStatus?.id !== MaintenanceStatusId.SOLICITED,
      },
      {
        id: `${baseUrl}historico/`,
        href: `${baseUrl}historico/`,
        label: "Histórico",
      },
      {
        id: `${baseUrl}avaliacoes/`,
        href: `${baseUrl}avaliacoes/`,
        label: "Avaliações",
        shouldRender:
          maintenanceStatus?.id !== AssistanceStatus.UNDER_REVIEW &&
          maintenanceStatus?.id !== MaintenanceStatusId.SOLICITED,
      },
      {
        id: `${baseUrl}assinaturas/`,
        href: `${baseUrl}assinaturas/`,
        label: "Assinaturas",
        shouldRender:
          maintenanceStatus?.id !== AssistanceStatus.UNDER_REVIEW &&
          maintenanceStatus?.id !== MaintenanceStatusId.SOLICITED,
      },
    ].filter((tab) => tab.shouldRender ?? true),
  );
  return (
    <QueryHandler
      {...maintenanceQuery}
      renderLoading={MaintenanceDetailLoading}
      renderError={(error) => <MaintenanceDetailError error={error} />}
    >
      {(maintenance) => (
        <CallDetailData maintenance={maintenance}>
          <AnimatedContainer className="mt-0">
            <Tabs
              aria-label="Módulos da construtora"
              tabs={tabs}
              selectedKey={pathname}
              className="rounded-none rounded-b-lg px-6 shadow-none"
            >
              <TabPanel id={pathname}>
                <Outlet />
              </TabPanel>
            </Tabs>
          </AnimatedContainer>
        </CallDetailData>
      )}
    </QueryHandler>
  );
}
