import { DropEvent } from "@react-aria/dnd";
import { DocumentDownload, Trash } from "iconsax-react";
import { useEffect, useRef } from "react";
import {
  Button,
  DialogTrigger,
  DropZone,
  type FileDropItem,
  FileTrigger,
  FileTriggerProps,
  Label,
  Text,
} from "react-aria-components";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import {
  useMutationDocumentDownload,
  useMutationFileUpload,
} from "@/shared/hooks/api";
import { cn, megabyte } from "@/shared/utils";
import { isImage } from "@/shared/utils/validators";

import { FileTriggerContent, ModalImagePreview, Spinner } from "..";

interface FormFileInput<T extends FieldValues> extends FileTriggerProps {
  className?: string;
  label?: string;
  description?: string;
  control: Control<T>;
  name: Path<T>;
  maxFileSize?: number;
}

export function FormFileInput<T extends FieldValues>({
  className,
  name,
  label,
  description,
  control,
  maxFileSize = 5 * megabyte,
  ...fileTriggerProps
}: FormFileInput<T>) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const documentDownload = useMutationDocumentDownload();

  const uploadMutation = useMutationFileUpload({
    maxFileSize,
    onSuccess: (data) => {
      onChange(data);
      onBlur();
    },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current!.name = name;
  }, [name]);

  const handleOnDrop = async (e: DropEvent) => {
    const dropFiles = e.items.filter(
      (file) => file.kind === "file",
    ) as FileDropItem[];
    const files = await Promise.all(dropFiles.map((file) => file.getFile()));

    await uploadMutation.mutateAsync(files);
  };

  const handleOnSelect = async (e: FileList | null) => {
    const files = Array.from(e ?? []);

    await uploadMutation.mutateAsync(files);
  };

  const isImageFile = isImage(value?.[0]?.key);

  return (
    <div className={cn("flex flex-col gap-2", className)}>
      {label && (
        <Label className="text-sm font-semibold text-gray-12">{label}</Label>
      )}
      <DropZone
        className={cn(
          "group relative z-10 flex size-52 self-start rounded-lg border border-dashed border-primary bg-primary-light-1 text-xs text-primary",
          className,
        )}
        onDrop={handleOnDrop}
      >
        <div className="absolute -z-10 h-full w-full rounded-lg bg-primary-light-1 group-rac-drop-target:animate-ping" />
        {isImageFile && (
          <DialogTrigger>
            <Button className="absolute left-0 top-0 h-full w-full rounded-lg" />
            <ModalImagePreview file={value} />
          </DialogTrigger>
        )}
        {!isImageFile && value && (
          <div className="absolute flex h-full w-full flex-col items-center justify-center gap-2 group-rac-drop-target:animate-bounce-up">
            <DocumentDownload
              className="cursor-pointer"
              variant="Bold"
              onClick={() => documentDownload.mutate(value[0].key as string)}
            />
            <Text
              className="block w-44 truncate text-center text-sm font-bold"
              slot="label"
            >
              {value[0].fileName}
            </Text>
          </div>
        )}
        {(value?.length ?? 0) > 0 && (
          <Button
            className="absolute right-0 top-0 m-2 text-error"
            onPress={() => {
              uploadMutation.reset();
              onChange(null);
            }}
          >
            <Trash variant={"Bold"} />
          </Button>
        )}
        <FileTrigger
          {...fileTriggerProps}
          onSelect={handleOnSelect}
          ref={inputRef}
        >
          {uploadMutation.isPending ? (
            <div
              className="flex h-full w-full items-center justify-center"
              hidden
            >
              <Spinner />
            </div>
          ) : (
            <Button className="flex h-full w-full flex-col items-center justify-center gap-2 p-4 font-medium">
              <FileTriggerContent file={value} isImage={isImageFile} />
            </Button>
          )}
        </FileTrigger>
      </DropZone>

      {description && (
        <Text slot="description" className="text-xs font-semibold text-gray-12">
          {description}
        </Text>
      )}
      <Text className="text-xs font-semibold text-error">{error?.message}</Text>
    </div>
  );
}
