import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsRevisaoGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UsePreventiveReviewsFilters = GetObjsRevisaoGetData;

export function usePreventiveReviews(
  initial: UsePreventiveReviewsFilters = {},
) {
  const [filters, updateFilters] =
    useObjReducer<UsePreventiveReviewsFilters>(initial);
  const query = useQuery({
    queryKey: ["preventiveReviews", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.RevisaosService.getObjsRevisaoGet,
      ),
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [
    query,
    {
      filters,
      updateFilters,
      handleSearchChange,
    },
  ] as const;
}
