import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { ModalOverlayProps } from "react-aria-components";
import { useForm } from "react-hook-form";

import { Button, Dialog, FormRichTextInput, Modal } from "@/shared/components";
import { useMutationPreventiveReviewReject } from "@/shared/hooks/api";
import { z } from "@/shared/utils";

const RejectSchema = z.object({
  reprovacao_justificativa: z.string(),
});
type Reject = z.infer<typeof RejectSchema>;

export interface PreventiveReviewRejectModalProps
  extends Omit<ModalOverlayProps, "children"> {
  preventiveReviewId: number;
}

export function PreventiveReviewRejectModal({
  preventiveReviewId,
  ...props
}: PreventiveReviewRejectModalProps) {
  const preventiveReviewUpdateMutation = useMutationPreventiveReviewReject({
    onSettled: () => {
      props.onOpenChange?.(false);
    },
  });

  const { formState, control, handleSubmit, reset } = useForm<Reject>({
    resolver: zodResolver(RejectSchema),
  });

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Justificativa"
        renderButtons={() => (
          <>
            <Button
              className="px-7 py-3"
              showSpinner={preventiveReviewUpdateMutation.isPending}
              onPress={() =>
                handleSubmit((values) =>
                  preventiveReviewUpdateMutation.mutateAsync({
                    id: preventiveReviewId,
                    requestBody: {
                      reprovacao_justificativa: values.reprovacao_justificativa,
                    },
                  }),
                )()
              }
            >
              Confirmar
            </Button>
          </>
        )}
      >
        <form className="grid grid-cols-2 gap-x-4 gap-y-6 p-1">
          <FormRichTextInput
            className="col-span-2"
            control={control}
            label="Descrição"
            name="reprovacao_justificativa"
          />
        </form>
      </Dialog>
    </Modal>
  );
}
