import { z } from "@/shared/utils";

export const PreventiveFilterSchema = z.object({
  empreendimentoId: z.number().int(),
  manualId: z.number().int(),
  unidadeId: z.number().int(),
  blocoId: z.string().trim().nullish(),
  sistemaId: z.number().int(),
  subsistemaId: z.number().int(),
  produtoId: z.number().int().nullish(),
});

export type PreventiveFilter = z.infer<typeof PreventiveFilterSchema>;
