import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { Revisao, apiProvider } from "@/shared/services";

interface UsePreventiveReviewOptions
  extends Omit<
    UseQueryOptions<Revisao, Error, Revisao>,
    "queryKey" | "queryFn"
  > {}

export function usePreventiveReview(
  preventiveId: string,
  options?: UsePreventiveReviewOptions,
) {
  return useQuery({
    queryKey: ["preventiveReview", preventiveId],
    queryFn: () =>
      apiProvider.services.RevisaosService.getOneObjRevisaoIdGet({
        id: +preventiveId,
      }),
    enabled: !!preventiveId,
    ...options,
  });
}
