import { Danger } from "iconsax-react";
import tinycolor from "tinycolor2";

import { RevisaoSolicitacao, StatusRevisao } from "@/shared/services";
import {
  PreventiveReviewStatusId,
  displayDateHourMin,
  sanitize,
} from "@/shared/utils";

interface StatusInfoCardProps {
  statusRevisao: StatusRevisao;
  solicitation?: RevisaoSolicitacao;
  solicitationIndex?: number;
  rejectionReason?: string | null;
}

export function StatusInfoCard({
  statusRevisao,
  solicitation,
  solicitationIndex,
  rejectionReason,
}: StatusInfoCardProps) {
  return (
    <div
      className="relative gap-4 rounded-lg border-b border-r border-t"
      style={{
        borderColor: statusRevisao.cor,
        backgroundColor: tinycolor(statusRevisao.cor)
          .setAlpha(0.2)
          .toRgbString(),
      }}
    >
      <div
        className="absolute left-0 top-0 h-full w-2 rounded-l-lg"
        style={{ backgroundColor: statusRevisao.cor }}
      />
      <div className="flex gap-3 px-4 py-3">
        <Danger
          variant="Bold"
          size={24}
          className="flex-shrink-0"
          style={{ color: statusRevisao.cor }}
        />
        <div className="flex flex-col gap-2">
          {statusRevisao.id === PreventiveReviewStatusId.REJECTED && (
            <span className="text-sm font-bold text-black-2">
              Manutenção reprovada | Justificativa
            </span>
          )}

          {statusRevisao.id === PreventiveReviewStatusId.SOLICITATION && (
            <div className="flex flex-col gap-1 text-black-2">
              <span className="text-sm font-bold">
                {solicitationIndex}ª atualização desse detalhamento
              </span>
              <span className="text-xs font-medium text-gray-10">
                Última atualização em:{" "}
                <span className="font-bold">
                  {displayDateHourMin(solicitation?.criadoEm)}
                </span>
              </span>
            </div>
          )}
          {statusRevisao.id === PreventiveReviewStatusId.SOLICITATION ? (
            <span
              className="text-xs font-medium text-gray-10"
              dangerouslySetInnerHTML={{
                __html: sanitize(solicitation?.mensagem ?? "Vazio"),
              }}
            />
          ) : (
            <span
              className="text-xs font-medium text-gray-10"
              dangerouslySetInnerHTML={{
                __html: sanitize(rejectionReason ?? "Vazio"),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
