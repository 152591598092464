import { apiProvider } from "@/shared/services";

import { ComboBoxApiResourceInput, ComboBoxApiResourceInputProps } from ".";

type ServiceFunction =
  typeof apiProvider.services.SubsistemasService.getObjsSubsistemaGet;

export type ComboBoxSubsystemInputProps<T extends object> = Omit<
  ComboBoxApiResourceInputProps<
    T,
    Awaited<ReturnType<ServiceFunction>>["content"][number],
    Parameters<ServiceFunction>[0]
  >,
  "service" | "queryKey" | "label" | "mapElementsKey"
>;
export function ComboBoxSubsystemInput<T extends object>(
  props: ComboBoxSubsystemInputProps<T>,
) {
  return (
    <ComboBoxApiResourceInput
      label="Subsistema"
      queryKey={["subsystem"]}
      service={async ({ pageParam: page }, extraFilters) =>
        await apiProvider.services.SubsistemasService.getObjsSubsistemaGet({
          page,
          elementsPerPage: PAGE_COUNT,
          ...extraFilters,
        })
      }
      mapElementsKey={(e) => ({ id: e.id, text: e.nome })}
      {...props}
    />
  );
}
