import { FieldValues } from "react-hook-form";

import {
  FormSelectInput,
  FormSelectInputProps,
  InputItem,
} from "@/shared/components";

export interface FormSelectPreventivePriorityInputProps<
  T extends object,
  TFieldValues extends FieldValues,
> extends FormSelectInputProps<T, TFieldValues> {}

export function FormSelectPreventivePriorityInput<
  T extends object,
  TFieldValues extends FieldValues,
>({ ...props }: FormSelectPreventivePriorityInputProps<T, TFieldValues>) {
  return (
    <FormSelectInput {...props}>
      <InputItem id={1} textValue="Obrigatório">
        Obrigatório
      </InputItem>
      <InputItem id={2} textValue="Recomendado">
        Recomendado
      </InputItem>
    </FormSelectInput>
  );
}
