import { Add } from "iconsax-react";
import { DialogTrigger } from "react-aria-components";

import {
  AnimatedContainer,
  Button,
  InfiniteQueryHandler,
  QueryHandler,
  Separator,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteAppointments, useMaintenance } from "@/shared/hooks/api";
import { MaintenanceTypeId } from "@/shared/utils";

import { AppointmentCard, AppointmentCreateModal } from "../components";

export function AppointmentList() {
  const { serviceId } = useRequiredParams();
  const [{ flatData, isFetching, error }, , { ref }] = useInfiniteAppointments({
    manutencaoId: +serviceId,
  });
  const maintenanceQuery = useMaintenance(serviceId, { refetchOnMount: false });

  return (
    <QueryHandler {...maintenanceQuery}>
      {(maintenance) => (
        <AnimatedContainer className="mt-0 overflow-auto rounded-lg bg-white-0 px-6 pt-4">
          <div className="flex items-center gap-4">
            <span className="text-base font-bold text-black-2">
              Agendamentos
            </span>
            <DialogTrigger>
              <Button styleType="default" className="gap-2 py-1">
                <Add size={24} /> Agendar vistoria
              </Button>
              <AppointmentCreateModal
                initialModalPage={
                  maintenance?.tipoManutencaoId === MaintenanceTypeId.PREVENTIVE
                    ? 2
                    : undefined
                }
                manutencaoId={+serviceId}
                useFormProps={{
                  defaultValues: {
                    responsavelId: maintenance?.colaboradorResponsavelId,
                    appointmentType:
                      maintenance?.tipoManutencaoId ===
                      MaintenanceTypeId.PREVENTIVE
                        ? "in-person"
                        : undefined,
                  },
                }}
              />
            </DialogTrigger>
          </div>
          <Separator />
          <div className="flex flex-col pb-6">
            <InfiniteQueryHandler
              isFetching={isFetching}
              error={error}
              isEmpty={!flatData?.length}
            >
              <div className="flex flex-col gap-4">
                {flatData?.map((appointment) => (
                  <AppointmentCard
                    key={appointment.id}
                    appointment={appointment}
                    ref={appointment.id === flatData.length - 1 ? ref : null}
                  />
                ))}
              </div>
            </InfiniteQueryHandler>
          </div>
        </AnimatedContainer>
      )}
    </QueryHandler>
  );
}
