import { Button as AriaButton, ModalOverlayProps } from "react-aria-components";

import { Dialog, Modal, SearchInput, Spinner } from "@/shared/components";
import { useInfiniteCompanyWorkers } from "@/shared/hooks/api";
import { Usuario } from "@/shared/services";

interface ResponsibleListModalProps
  extends Omit<ModalOverlayProps, "children"> {
  onSelected?: (id: Usuario) => void;
  companyId: number;
}

export function ResponsibleListModal({
  onSelected,
  companyId,
  ...props
}: ResponsibleListModalProps) {
  const [{ flatData, isFetching }, { handleSearchChange }, { ref }] =
    useInfiniteCompanyWorkers({ construtoraId: companyId });

  return (
    <Modal isDismissable {...props}>
      <Dialog className="w-screen max-w-[60vw]" title="Escolha um Responsável">
        {({ close }) => (
          <div className="space-y-4">
            <SearchInput onDebounceChange={handleSearchChange} />
            <div className="scrollbar-custom flex h-screen max-h-[30vh] flex-col space-y-3 overflow-y-scroll pr-2">
              {flatData?.map((e, i) => (
                <AriaButton
                  key={e.id}
                  ref={i === flatData.length - 1 ? ref : null}
                  className="mr-4 rounded-lg border border-white-3 p-3 text-left"
                  onPress={() => {
                    onSelected?.(e);
                    close();
                  }}
                >
                  {e.nome}
                </AriaButton>
              ))}
              {isFetching && <Spinner className="mx-auto" />}
            </div>
          </div>
        )}
      </Dialog>
    </Modal>
  );
}
