import { PlayCircle } from "iconsax-react";
import { forwardRef } from "react";

import { VideoPlayer } from ".";

interface VideoPreviewProps {
  onClick?: () => void;
  fileUrl: string;
}
export const VideoPreview = forwardRef<HTMLDivElement, VideoPreviewProps>(
  ({ fileUrl, onClick }, ref) => {
    return (
      <div
        className="relative h-56 w-72 cursor-pointer justify-center rounded-md bg-primary-light-1 text-primary"
        onClick={onClick}
        ref={ref}
      >
        <VideoPlayer
          className="h-full w-full rounded-lg"
          url={fileUrl}
          controls={false}
        />
        <PlayCircle
          variant="Bold"
          size={32}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white-0"
        />
      </div>
    );
  },
);
