import { usePrevious } from "@uidotdev/usehooks";
import {
  Table as AriaTable,
  TableProps as AriaTableProps,
  Column,
  TableBody,
  TableHeader,
} from "react-aria-components";

import { CollectionEmpty, CollectionEmptyProps, TableSkeleton } from ".";
import { cn } from "../utils";

export interface TableProps<T> extends AriaTableProps {
  headers: string[];
  renderRow: (data: T, index: number) => React.ReactNode;
  rowsData?: T[];
  isLoading?: boolean;
  emptyProps: CollectionEmptyProps;
  removeDetailsColumn?: boolean;
  headerClassName?: string;
}

export function Table<T>({
  headers,
  renderRow,
  rowsData,
  isLoading,
  emptyProps,
  removeDetailsColumn,
  className,
  headerClassName,
  ...props
}: TableProps<T>) {
  const previousPageCount = usePrevious(rowsData?.length);

  const _headers = removeDetailsColumn ? headers : [...headers, ""];
  return (
    <>
      <AriaTable
        {...props}
        className={cn("w-full bg-white-0 text-left", className)}
      >
        <TableHeader className="text-sm font-bold text-gray-12">
          {_headers.map((header, i) => (
            <Column
              key={header}
              className={cn(
                "sticky top-0 z-10 bg-white-0 px-8 py-4",
                {
                  "pl-0 pr-6":
                    !removeDetailsColumn && i === _headers.length - 1,
                },
                headerClassName,
              )}
              isRowHeader={i === 0}
            >
              {header}
            </Column>
          ))}
        </TableHeader>
        <TableBody className="text-xs text-gray-12 *:border-y-2 *:border-y-white-2 *:*:px-8 *:*:py-4">
          {isLoading ? (
            <TableSkeleton
              rows={previousPageCount || 1}
              columns={_headers.length}
            />
          ) : (
            rowsData && rowsData.map(renderRow)
          )}
        </TableBody>
      </AriaTable>
      {!isLoading && !rowsData?.length && <CollectionEmpty {...emptyProps} />}
    </>
  );
}
