export const displayDate = (date?: string | null) => {
  if (!date) {
    return "Vazio";
  }
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat("pt-BR", {
    timeZone: "America/Sao_Paulo",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(dateObj);
};
