interface FrequencyStringProps {
  frequency?: number | null;
  timeUnit?: string | null;
}

export const frequencyString = ({
  frequency,
  timeUnit,
}: FrequencyStringProps) => {
  if (frequency && frequency > 1) {
    if (timeUnit === "mês") {
      return `A cada ${frequency} meses`;
    }
    return `A cada ${frequency} ${timeUnit}s`;
  }

  return `A cada ${frequency} ${timeUnit}`;
};
