import { SelectStatusReviewInput, SelectStatusReviewInputProps } from "..";

export interface StatusReviewFilterProps<T extends object = object>
  extends Omit<SelectStatusReviewInputProps<T>, "styleType" | "extraItems"> {}

export function StatusReviewFilter(props: StatusReviewFilterProps) {
  return (
    <SelectStatusReviewInput
      styleType="filter"
      extraItems={[{ id: -1, nome: "Todos" }]}
      {...props}
    />
  );
}
