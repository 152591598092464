import { Checkbox, CheckboxGroup, FieldError } from "react-aria-components";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface ModuleCheckBoxGroupProps<T extends FieldValues> {
  control: Control<T>;
}

export function ModuleCheckBoxGroup<T extends FieldValues>({
  control,
}: ModuleCheckBoxGroupProps<T>) {
  const {
    field: { onBlur, disabled, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name: "module" as Path<T>,
    control,
  });

  return (
    <CheckboxGroup
      {...inputProps}
      className="grid grid-cols-3 gap-4"
      aria-label="Modulos do empreendimento"
      isDisabled={disabled}
      isInvalid={invalid}
      validationBehavior="aria"
      defaultValue={["manual"]}
    >
      <Checkbox
        className="react-aria-Checkbox group"
        value="manual"
        onBlur={onBlur}
      >
        <div className="flex w-full items-start rounded-lg border border-white-3 bg-white-0 p-3 transition-all group-rac-selected:border-primary group-rac-selected:bg-primary-light-1">
          <div className="checkbox" aria-hidden="true">
            <svg viewBox="0 0 18 18">
              <polyline points="1 9 7 14 15 4" />
            </svg>
          </div>
          <span className="flex-1 py-8 text-center text-h4 group-rac-selected:font-bold">
            Manual Interativo
          </span>
        </div>
      </Checkbox>
      <Checkbox
        className="react-aria-Checkbox group"
        value="technical-support"
        onBlur={onBlur}
      >
        <div className="flex w-full items-start rounded-lg border border-white-3 bg-white-0 p-3 transition-all group-rac-selected:border-primary group-rac-selected:bg-primary-light-1">
          <div className="checkbox" aria-hidden="true">
            <svg viewBox="0 0 18 18">
              <polyline points="1 9 7 14 15 4" />
            </svg>
          </div>
          <span className="flex-1 py-8 text-center text-h4 group-rac-selected:font-bold">
            Assistência Técnica
          </span>
        </div>
      </Checkbox>
      <Checkbox
        className="react-aria-Checkbox group"
        value="preventive-management"
        onBlur={onBlur}
      >
        <div className="flex w-full items-start rounded-lg border border-white-3 bg-white-0 p-3 transition-all group-rac-selected:border-primary group-rac-selected:bg-primary-light-1">
          <div className="checkbox" aria-hidden="true">
            <svg viewBox="0 0 18 18">
              <polyline points="1 9 7 14 15 4" />
            </svg>
          </div>
          <span className="flex-1 py-8 text-center text-h4 group-rac-selected:font-bold">
            Gestão de Manutenção Preventiva
          </span>
        </div>
      </Checkbox>
      <FieldError className="text-xs font-semibold text-error">
        {error?.message}
      </FieldError>
    </CheckboxGroup>
  );
}
