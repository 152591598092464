import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsManutencaoGetData, apiProvider } from "@/shared/services";
import { handleListService } from "@/shared/utils";

export type UseMaintenancesFilters = GetObjsManutencaoGetData;

export function useMaintenances(initial: UseMaintenancesFilters = {}) {
  const [filters, updateFilters] =
    useObjReducer<UseMaintenancesFilters>(initial);
  const query = useQuery({
    queryKey: ["maintenances", filters],
    queryFn: async () =>
      handleListService(
        filters,
        apiProvider.services.ManutencaosService.getObjsManutencaoGet,
      ),
    refetchOnMount: true,
  });

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  return [
    query,
    {
      filters,
      updateFilters,
      handleSearchChange,
    },
  ] as const;
}
