import { Link } from "react-aria-components";

export interface PreventiveConfigItemProps {
  label: string;
  icon: React.ReactNode;
  href: string;
}

export function PreventiveConfigItem({
  label,
  icon,
  href,
}: PreventiveConfigItemProps) {
  return (
    <Link
      className="flex gap-3 rounded-lg border border-white-3 p-4"
      href={href}
    >
      {icon}
      <p className="font-semibold">{label}</p>
    </Link>
  );
}
