import { DocumentDownload } from "iconsax-react";
import { useState } from "react";

import {
  ImagePreview,
  Slider,
  SliderContent,
  VideoPreview,
} from "@/shared/components";
import { ManutencaoArquivo } from "@/shared/services";
import { isVideo } from "@/shared/utils/validators";

interface FileListContainerProps {
  files: ManutencaoArquivo[];
}
export function FileListContainer({ files }: FileListContainerProps) {
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  return (
    <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4">
      {files.map((file, index) => (
        <div key={file.id}>
          {file.seImagem ? (
            <ImagePreview
              fileUrl={file.arquivo}
              onClick={() => {
                setCarouselOpen(true);
                setCarouselIndex(index);
              }}
            />
          ) : isVideo(file.arquivo) ? (
            <VideoPreview
              fileUrl={file.arquivo}
              onClick={() => {
                setCarouselOpen(true);
                setCarouselIndex(index);
              }}
            />
          ) : (
            <div
              className="flex h-56 w-72 cursor-pointer flex-col items-center justify-center gap-2 rounded-md bg-primary-light-1 p-4 text-primary"
              onClick={() => {
                setCarouselOpen(true);
                setCarouselIndex(index);
              }}
            >
              <DocumentDownload variant="Bold" />
              <span className="truncate text-center text-sm font-bold">
                {file.arquivo}
              </span>
            </div>
          )}
        </div>
      ))}
      <Slider
        total={files.length}
        isOpen={carouselOpen}
        onClose={() => setCarouselOpen(false)}
        selectedIndex={carouselIndex}
        setCarouselIndex={setCarouselIndex}
      >
        {files.map((file) => (
          <SliderContent fileUrl={file.arquivo} key={file.id} />
        ))}
      </Slider>
    </div>
  );
}
