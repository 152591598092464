import React from "react";
import {
  Switch as AriaSwitch,
  SwitchProps as AriaSwitchProps,
} from "react-aria-components";
import { tv } from "tailwind-variants";

import { cn } from "../utils";

export interface SwitchProps extends Omit<AriaSwitchProps, "children"> {
  children?: React.ReactNode;
  className?: string;
}

const track = tv({
  base: "flex h-6 w-10 shrink-0 cursor-default items-center rounded-full border border-transparent shadow-inner transition duration-200 ease-in-out",
  variants: {
    isSelected: {
      false: "group-pressed:bg-gray-500 bg-gray-1",
      true: "group-pressed:bg-gray-800 bg-primary forced-colors:!bg-[Highlight]",
    },
    isDisabled: {
      true: "bg-gray-200 dark:bg-zinc-700 forced-colors:group-selected:!bg-[GrayText] forced-colors:border-[GrayText]",
    },
  },
});

const handle = tv({
  base: "mx-0.5 h-5 w-5 transform rounded-full bg-white-3 outline outline-1 -outline-offset-1 outline-transparent transition duration-200 ease-in-out",
  variants: {
    isSelected: {
      false: "translate-x-0",
      true: "translate-x-[75%]",
    },
    isDisabled: {
      true: "forced-colors:outline-[GrayText]",
    },
  },
});

export function Switch({ children, className, ...props }: SwitchProps) {
  return (
    <div className="flex items-center">
      <AriaSwitch
        {...props}
        className={cn(
          className,
          "text-gray-800 disabled:text-gray-300 dark:text-zinc-200 dark:disabled:text-zinc-600 group flex items-center gap-1 rounded-2xl transition forced-colors:disabled:text-[GrayText]",
        )}
      >
        {(renderProps) => (
          <>
            <div className={track(renderProps)}>
              <span className={handle(renderProps)} />
            </div>
            {children}
          </>
        )}
      </AriaSwitch>
    </div>
  );
}
