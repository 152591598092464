import { Accordion, InfiniteQueryHandler } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useInfiniteWarranty, useMaintenance } from "@/shared/hooks/api";

import { FailureInfiniteList } from "./failure-infinite-list";

export function SubsystemWarrantyData() {
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId, {
    refetchOnMount: false,
  });

  const [{ flatData, isFetching, error }, , { ref }] = useInfiniteWarranty({
    text: "",
    status: true,
    subsistemaId: maintenanceQuery.data?.subsistemaId,
  });

  return (
    <div className="flex flex-col gap-2 px-2">
      <InfiniteQueryHandler
        isFetching={isFetching}
        error={error}
        isEmpty={!flatData?.length}
      >
        {flatData?.map((warranty, index) => (
          <Accordion
            title={warranty?.nome}
            key={warranty.id}
            ref={index === flatData.length - 1 ? ref : null}
          >
            <div className="flex flex-col gap-2">
              <FailureInfiniteList key={warranty.id} garantiaId={warranty.id} />
            </div>
          </Accordion>
        ))}
      </InfiniteQueryHandler>
    </div>
  );
}
