import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { usePreventive } from "@/shared/hooks/api";
import { ApiError } from "@/shared/services";

import { PreventiveCreateForm } from "../../create/components";

export function PreventiveEdit() {
  const { subsystemId, productId, preventiveId } = useRequiredParams();
  const preventiveQuery = usePreventive(preventiveId);

  const defaultValues = productId
    ? { produtoId: +productId }
    : { subsistemaId: subsystemId ? +subsystemId : undefined };

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Editar manutenção</PageTitle>
        <QueryHandler
          {...preventiveQuery}
          renderLoading={() => <Spinner className="mx-auto" />}
          renderError={(error) => (
            <ErrorContainer
              message={
                error instanceof ApiError && error.status === 404
                  ? `Preventido não encontrado...`
                  : undefined
              }
            />
          )}
        >
          {(data) => (
            <PreventiveCreateForm
              redirectTo="../"
              useFormProps={{
                defaultValues: {
                  ...defaultValues,
                  nome: data?.nome,
                  descricao: data?.descricao,
                  frequencia: data?.frequencia ?? undefined,
                  unidadeTempoId: data?.unidadeTempoId,
                  nbrId: data?.nbrId ?? undefined,
                  tipoClienteId: data?.tipoClienteId,
                  componente: data?.componente,
                  prioridadeId: data?.prioridadeId,
                  responsavel: data?.responsavel,
                },
              }}
            />
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
