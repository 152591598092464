import { z } from "@/shared/utils";

import { WarrantyFailureCommonSchema } from "../detail/containers";

export const WarrantyCommonSchema = z.object({
  nome: z.string().min(1, "Obrigatório").trim(),
  falhas: z.array(WarrantyFailureCommonSchema).default([]),
  notas: z.string().trim().nullish(),
});
export type WarrantyCommon = z.infer<typeof WarrantyCommonSchema>;

export const WarrantyCreateSchema = z.object({
  ...WarrantyCommonSchema.shape,
  subsistemaId: z.number().int().nullish(),
  produtoId: z.number().int().nullish(),
});
export type WarrantyCreate = z.infer<typeof WarrantyCreateSchema>;

export const WarrantyEditSchema = z.object({
  nome: z.string().min(1, "Obrigatório").trim(),
  notas: z.string().trim().nullish(),
  subsistemaId: z.number().int().nullish(),
  produtoId: z.number().int().nullish(),
});
export type WarrantyEdit = z.infer<typeof WarrantyEditSchema>;
