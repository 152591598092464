import { useInfiniteQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import { useIntersectionObserver } from "usehooks-ts";

import { useObjReducer } from "@/shared/hooks";
import { GetObjsRevisaoArquivoGetData, apiProvider } from "@/shared/services";

type UseInfinitePreventiveReviewFilesFilters = Omit<
  GetObjsRevisaoArquivoGetData,
  "page" | "elementsPerPage"
>;

export function useInfinitePreventiveReviewFiles(
  initial?: UseInfinitePreventiveReviewFilesFilters,
) {
  const trigger = useIntersectionObserver({
    threshold: 0.5,
  });

  const [filters, updateFilters] =
    useObjReducer<UseInfinitePreventiveReviewFilesFilters>({ ...initial });

  const query = useInfiniteQuery({
    queryKey: ["preventiveReviewFilesInfinite", filters],
    initialPageParam: 0,
    queryFn: ({ pageParam }) => {
      return apiProvider.services.RevisaoArquivosService.getObjsRevisaoArquivoGet(
        {
          page: pageParam,
          elementsPerPage: PAGE_COUNT,
          ...filters,
        },
      );
    },
    getNextPageParam: (lastPage, pages) => {
      const apiPages = Math.ceil(lastPage.total / lastPage.elementPerPage);
      return apiPages > pages.length ? pages.length : undefined;
    },
  });

  const { hasNextPage, isFetching, fetchNextPage } = query;
  useEffect(() => {
    if (trigger.isIntersecting && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, trigger.isIntersecting]);

  const handleSearchChange = useCallback(
    (text: string) => updateFilters({ text }),
    [updateFilters],
  );

  const flatData = useMemo(
    () => query.data?.pages.flatMap((p) => p.content),
    [query.data?.pages],
  );

  const total = query.data?.pages[0]?.total ?? 0;

  return [
    { flatData, ...query },
    { filters, updateFilters, handleSearchChange },
    total,
    trigger,
  ] as const;
}
