import { apiProvider } from "@/shared/services";

import { SelectApiResourceInput, SelectApiResourceInputProps } from ".";

export type SelectStatusReviewInputProps<T extends object> = Omit<
  SelectApiResourceInputProps<T>,
  "service" | "queryKey" | "label"
>;

export function SelectStatusReviewInput<T extends object>(
  props: SelectStatusReviewInputProps<T>,
) {
  return (
    <SelectApiResourceInput
      label="Status"
      queryKey={["status"]}
      service={
        apiProvider.services.StatusRevisoesService.getAllStatusRevisaoGet
      }
      {...props}
    />
  );
}
