import { DocumentDownload } from "iconsax-react";
import { forwardRef } from "react";

import { DownloadFile } from "@/shared/components";
import { useMutationFileDownload } from "@/shared/hooks/api";

interface FileCardProps {
  title?: string;
}

export const FileCard = forwardRef<HTMLDivElement, FileCardProps>(
  ({ title }, ref) => {
    const fileMutation = useMutationFileDownload();

    return (
      <div ref={ref}>
        <DownloadFile
          className="flex min-w-64 cursor-pointer justify-between gap-2 rounded-lg bg-primary-light-1 px-4 py-5 text-primary"
          onPress={() => {
            title && fileMutation.mutateAsync(title);
          }}
          isPending={fileMutation.isPending}
        >
          <span className="text-base font-medium">
            <span className="font-bold">Baixar:</span> {title}
          </span>
          <DocumentDownload variant="Bold" />
        </DownloadFile>
      </div>
    );
  },
);
