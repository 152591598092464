import { Accordion } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useMaintenance } from "@/shared/hooks/api";

import {
  ContactsData,
  FaqData,
  MissingWarranty,
  ProductWarrantyData,
  SolicitationData,
  SubsystemWarrantyData,
} from "../components";
import { ClientSchedule } from "../components/client-schedule";

export function CallGeneralData() {
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId, {
    refetchOnMount: false,
  });

  return (
    <div className="flex flex-col gap-4">
      <Accordion title="Dados da solicitação" defaultOpen>
        <SolicitationData />
      </Accordion>
      <Accordion title="Disponibilidade do cliente">
        <ClientSchedule />
      </Accordion>
      <Accordion title="Garantias de uso do subsistema">
        <SubsystemWarrantyData />
      </Accordion>

      {!!maintenanceQuery.data?.produtoId && (
        <Accordion title="Garantias de uso do produto">
          <ProductWarrantyData />
        </Accordion>
      )}

      <Accordion title="Contatos">
        <ContactsData />
      </Accordion>
      <Accordion title="FAQ">
        <FaqData />
      </Accordion>
      <Accordion title="Perda de garantia">
        <MissingWarranty />
      </Accordion>
    </div>
  );
}
