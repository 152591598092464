import { Cell } from "react-aria-components";

import {
  AnimatedContainer,
  Breadcrumbs,
  Card,
  Checkbox,
  ComboBoxProductInput,
  ComboBoxSubsystemInput,
  FieldSetTitle,
  PaginationButtons,
  SearchInput,
  Table,
  TableRow,
} from "@/shared/components";
import {
  useMutationAnalyzeReviewUpdate,
  usePreventives,
} from "@/shared/hooks/api";
import { nullishStringMask } from "@/shared/utils/masks";

export function PreventiveReviewMaintenance() {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    usePreventives({
      text: "",
    });

  const mutationAnalyzeReviewUpdate = useMutationAnalyzeReviewUpdate();

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
      </div>
      <Card className="shadow-header">
        <FieldSetTitle>Defina as manutenções que serão revisadas</FieldSetTitle>
        <div className="mb-4 grid grid-cols-2 gap-2">
          <ComboBoxSubsystemInput
            styleType="filter"
            onSelectionChange={(subsystem) =>
              updateFilters({
                subsistemaId: subsystem ? +subsystem : undefined,
              })
            }
          />

          <ComboBoxProductInput
            styleType="filter"
            filters={{ subsistemaId: filters.subsistemaId }}
            onSelectionChange={(product) =>
              updateFilters({
                produtoId: product ? +product : undefined,
              })
            }
          />
        </div>
        <div className="flex gap-3">
          <SearchInput
            className="flex-1"
            onDebounceChange={handleSearchChange}
          />
        </div>
        <p className="my-3 font-normal text-typography-2">
          Quais as manutenções serão revisadas?
        </p>
        <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0 shadow-header">
          <div className="overflow-y-auto">
            <Table
              aria-label="Lista chamados de assistêcia"
              headerClassName="bg-primary-light-1"
              headers={[
                "",
                "Nome",
                "Tipo",
                "Obrigatoriedade",
                "Sistema",
                "Subsistema",
                "Produto",
              ]}
              rowsData={data?.content}
              isLoading={isLoading}
              emptyProps={{
                resource: "revisão de manutenção",
                search: filters.text ?? undefined,
              }}
              renderRow={(data) => (
                <TableRow key={data.id}>
                  <Cell>
                    <Checkbox
                      value={data.id.toString()}
                      isDisabled={mutationAnalyzeReviewUpdate.isPending}
                      isSelected={data.analisar}
                      onChange={(status) =>
                        mutationAnalyzeReviewUpdate.mutateAsync({
                          id: +data.id,
                          requestBody: { analisar: status },
                        })
                      }
                    />
                  </Cell>
                  <Cell>{nullishStringMask(data.nome)}</Cell>
                  <Cell>{data.tipoCliente.nome}</Cell>
                  <Cell>{data.prioridade.nome}</Cell>
                  <Cell>
                    {nullishStringMask(data.subsistema?.sistema.nome)}
                  </Cell>
                  <Cell>{nullishStringMask(data.subsistema?.nome)}</Cell>
                  <Cell>{nullishStringMask(data.produto?.nome)}</Cell>
                </TableRow>
              )}
            />
          </div>
          <PaginationButtons
            currentPage={filters.page ?? 1}
            pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
            onPageChange={(page) => updateFilters({ page })}
          />
        </div>
      </Card>
    </AnimatedContainer>
  );
}
