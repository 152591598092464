import { useQuery } from "@tanstack/react-query";

import { InputItem, SelectInput, SelectInputProps } from "@/shared/components";

export interface SelectApiResourceInputItem {
  id: number;
  nome: string;
}
export interface SelectApiResourceInputProps<T extends object>
  extends SelectInputProps<T> {
  queryKey: string[];
  extraItems?: SelectApiResourceInputItem[];
  service: () => Promise<SelectApiResourceInputItem[]>;
}

export function SelectApiResourceInput<T extends object>({
  isDisabled,
  queryKey,
  extraItems,
  service,
  ...props
}: SelectApiResourceInputProps<T>) {
  const { data, isLoading, isError } = useQuery({
    queryKey: queryKey,
    queryFn: service,
  });

  const _elements = data ? extraItems?.concat(data) : extraItems;

  return (
    <SelectInput {...props} isDisabled={isDisabled || isLoading || isError}>
      {_elements?.map((element) => (
        <InputItem key={element.id} id={element.id}>
          {element.nome}
        </InputItem>
      ))}
    </SelectInput>
  );
}
