import { Handle, Navigate, RouteObject } from "react-router";

import { serviceDetailRouter } from "../assistance/modules/services/modules/detail/router";
import { PreventiveLayout } from "./containers";
import { PreventiveConfig } from "./modules/config/containers";
import { configRouter } from "./modules/config/router";
import { ListPreventiveKanban } from "./modules/preventive-kanban/containers";
import { ListPreventiveReview } from "./modules/preventive-review/containers";
import { preventiveReviewDetailRouter } from "./modules/preventive-review/router";

export const preventiveRouter: RouteObject = {
  path: "preventivo",
  handle: {
    crumb: "Preventivos",
    tabBaseUrl: "preventive",
  } as Handle,
  children: [
    { index: true, element: <Navigate to="revisao/" /> },
    {
      element: <PreventiveLayout />,
      children: [
        {
          path: "revisao",
          handle: {
            crumb: "Revisão de manutenção",
            action: "list.preventiveReview",
          } as Handle,
          element: <ListPreventiveReview />,
        },
        {
          path: "servico",
          handle: {
            crumb: "Serviço de manutenção",
            action: "list.maintenance",
          } as Handle,
          element: <ListPreventiveKanban />,
        },

        {
          path: "calendario",
          handle: {
            crumb: "Calendário",
            /* TODO: Add pending action */
          } as Handle,
          element: <p className="m-4 text-xl font-bold">Em desenvolvimento</p>,
        },
        {
          path: "configuracoes",
          handle: {
            crumb: "Configurações",
            /* TODO: Add pending action */
          } as Handle,
          element: <PreventiveConfig />,
        },
      ],
    },
    {
      path: "servico",
      handle: {
        crumb: "Serviço de manutenção",
        action: "get.maintenance",
      } as Handle,
      children: [serviceDetailRouter],
    },
    preventiveReviewDetailRouter,
    configRouter,
  ],
};
