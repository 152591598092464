import { DocumentDownload } from "iconsax-react";

import { S3Image, S3Video } from "@/shared/components";
import { useMutationDocumentDownload } from "@/shared/hooks/api";
import { isImage, isVideo } from "@/shared/utils/validators";

interface SliderComponentProps {
  fileUrl?: string;
}
export function SliderContent({ fileUrl }: SliderComponentProps) {
  const documentDownload = useMutationDocumentDownload();
  return (
    <div className="flex h-[calc(100vh-8rem)] flex-1 items-center justify-center">
      {isImage(fileUrl) ? (
        <S3Image
          file={fileUrl}
          className="h-full rounded-xl object-contain"
          emptyIcon="default"
        />
      ) : isVideo(fileUrl) ? (
        <S3Video file={fileUrl} className="h-full object-contain" />
      ) : (
        <div
          className="flex h-full w-2/3 cursor-pointer flex-col items-center justify-center gap-2 rounded-md bg-primary-light-1 p-4 text-primary"
          onClick={() => documentDownload.mutate(fileUrl as string)}
        >
          <DocumentDownload size={24} variant="Bold" />
          <span className="truncate text-sm font-bold">{fileUrl}</span>
        </div>
      )}
    </div>
  );
}
