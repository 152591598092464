import { apiProvider } from "@/shared/services";

import { SelectApiResourceInput, SelectApiResourceInputProps } from ".";

export type SelectClientTypeInputProps<T extends object> = Omit<
  SelectApiResourceInputProps<T>,
  "service" | "queryKey" | "label"
>;

export function SelectClientTypeInput<T extends object>(
  props: SelectClientTypeInputProps<T>,
) {
  return (
    <SelectApiResourceInput
      label="Tipo do cliente"
      queryKey={["clientType"]}
      service={
        apiProvider.services.TiposClientesService.getObjsTiposClientesGet
      }
      {...props}
    />
  );
}
