import { Time, getLocalTimeZone, parseDateTime } from "@internationalized/date";
import { useFormContext } from "react-hook-form";

import {
  FormComboBoxCompanyWorkerInput,
  FormDateField,
  FormTextAreaInput,
  QueriesHandler,
  QueryHandler,
  Skeleton,
} from "@/shared/components";
import { AvailabilityCalendar } from "@/shared/components/availability-calendar/availability-calendar";
import { Interval } from "@/shared/components/form/availability/schema";
import {
  deserializeDisponibilidade,
  serializeDisponibilidade,
} from "@/shared/components/form/availability/serialization";
import { useCompany, useMaintenance, useProfile } from "@/shared/hooks/api";

import { AppointmentCreate } from "../schemas";

export function AppointmentDateForm() {
  const { control, watch, setValue } = useFormContext<AppointmentCreate>();

  const manutencaoId = watch("manutencaoId");
  const maintenanceQuery = useMaintenance(manutencaoId.toString(), {
    refetchOnMount: false,
  });
  const profileQuery = useProfile({ refetchOnMount: false });
  const companyQuery = useCompany(
    profileQuery.data?.construtoraId?.toString(),
    { refetchOnMount: false },
  );

  return (
    <div className="grid max-h-[50vh] grid-cols-2 gap-4">
      <FormTextAreaInput
        className="col-span-2"
        control={control}
        name="observacoes"
        label="Observações"
      />
      <QueriesHandler
        queries={[maintenanceQuery, profileQuery, companyQuery] as const}
        renderLoading={() => (
          <Skeleton className="col-span-2 h-[444px] w-[964px]" />
        )}
      >
        {([maintenanceData, , companyData]) => (
          <AvailabilityCalendar
            className="col-span-2"
            datesIntervals={
              maintenanceData
                ? serializeDisponibilidade(
                    deserializeDisponibilidade(
                      maintenanceData.disponibilidade,
                    ).map(
                      (dayAvailability) =>
                        dayAvailability?.reduce((acc, interval) => {
                          const meetingIntervals = [];
                          let currentTime: Time | null = interval.start;
                          while (currentTime) {
                            const meetingStart = currentTime;
                            let meetingEnd = currentTime.add({
                              minutes:
                                companyData?.company.duracaoMinutosReuniao ??
                                60,
                            });
                            if (meetingEnd >= interval.end) {
                              meetingEnd = interval.end;
                              currentTime = null;
                            } else {
                              currentTime = meetingEnd;
                            }
                            meetingIntervals.push({
                              start: meetingStart,
                              end: meetingEnd,
                            });
                          }

                          return acc.concat(meetingIntervals);
                        }, [] as Interval[]) ?? null,
                    ),
                  )
                : []
            }
            onIntervalSelected={(date, interval) => {
              const dateTime = parseDateTime(date.toString()).add({
                hours: interval.start.hour,
                minutes: interval.start.minute,
              });
              setValue("data", dateTime.toDate(getLocalTimeZone()));
            }}
          />
        )}
      </QueriesHandler>
      <div className="col-span-2 flex flex-1 gap-2 pb-6">
        <FormDateField
          className="w-full"
          label="Data"
          control={control}
          name="data"
          granularity="minute"
          hourCycle={24}
          hideTimeZone
          shouldForceLeadingZeros
        />
        <QueryHandler
          {...profileQuery}
          renderLoading={() => <Skeleton className="mt-7 h-auto" />}
        >
          {(data) =>
            data?.construtoraId && (
              <FormComboBoxCompanyWorkerInput
                className="w-full"
                name="responsavelId"
                control={control}
                label="Responsável"
                construtoraId={data.construtoraId}
              />
            )
          }
        </QueryHandler>
      </div>
    </div>
  );
}
