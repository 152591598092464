import { SelectClientTypeInput, SelectClientTypeInputProps } from "..";

export interface ClientTypeFilterProps<T extends object = object>
  extends Omit<SelectClientTypeInputProps<T>, "styleType" | "extraItems"> {}

export function ClientTypeFilter(props: ClientTypeFilterProps) {
  return (
    <SelectClientTypeInput
      styleType="filter"
      extraItems={[{ id: -1, nome: "Todos" }]}
      defaultSelectedKey={-1}
      {...props}
    />
  );
}
