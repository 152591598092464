import { ArrowRight2, Setting2, Setting3 } from "iconsax-react";

import { Link, QueryHandler, Separator, Switch } from "@/shared/components";
import {
  useCompany,
  useMutationCompanyReviewStatus,
  useProfile,
} from "@/shared/hooks/api";

export function PreventiveReviewService() {
  const mutationCompanyReviewStatus = useMutationCompanyReviewStatus();
  const profileQuery = useProfile({ refetchOnMount: false });
  const companyQuery = useCompany(profileQuery.data?.construtoraId?.toString());

  return (
    <QueryHandler {...companyQuery}>
      {(data) => (
        <div className="space-y-4 rounded-b-lg bg-white-0 p-4">
          <div className="flex gap-4 rounded border border-gray-1 p-4">
            <Setting2 className="size-6 text-primary" variant="Bold" />
            <div className="w-full">
              <div className="flex items-center justify-between">
                <p className="text-base font-semibold text-typography-1">
                  Essa construtora deseja revisar as manutenções?
                </p>

                {data && (
                  <Switch
                    isDisabled={mutationCompanyReviewStatus.isPending}
                    isSelected={data?.company.preventivosRevisaveis}
                    onChange={(status) => {
                      mutationCompanyReviewStatus.mutateAsync({
                        id: +(profileQuery.data?.construtoraId ?? 0),
                        requestBody: { preventivosRevisaveis: status },
                      });
                    }}
                  />
                )}
              </div>

              <Separator className="my-2" />
              <div className="flex flex-col gap-2">
                <p className="text-typography-2">
                  Configure abaixo as informações sobre o serviço de manutenção
                </p>
              </div>
            </div>
          </div>
          {data?.company.preventivosRevisaveis && (
            <>
              <p className="text-typography-2">Customizações da manutenção</p>
              <Link
                styleType="default"
                className="flex gap-4 rounded border border-gray-1 p-4"
                href="manutencoes/"
              >
                <Setting3 className="size-6 text-primary" variant="Bold" />
                <div className="group w-full transition-all">
                  <div className="flex items-center justify-between">
                    <p className="text-base font-semibold text-typography-1">
                      Definir manutenção que serão revisadas{" "}
                    </p>
                    <ArrowRight2 className="text-gray-6 transition-all group-hover:translate-x-1" />
                  </div>
                </div>
              </Link>
            </>
          )}
        </div>
      )}
    </QueryHandler>
  );
}
