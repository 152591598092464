import { FormProps as AriaFormProps } from "react-aria-components";
import { useActionData } from "react-router";

import {
  Button,
  Card,
  FieldSetTitle,
  Form,
  FormNbrPickerInput,
  FormNumberInput,
  FormProps,
  FormRichTextInput,
  FormRootError,
  FormSelectClientTypeInput,
  FormSelectPreventivePriorityInput,
  FormSelectTimeUnitInput,
  FormTextInput,
  Separator,
} from "@/shared/components";
import { useSubmitting } from "@/shared/hooks";

import { PreventiveCreateSchema } from "../schemas";

interface PreventiveCreateFormProps
  extends Omit<
    FormProps<typeof PreventiveCreateSchema>,
    "schema" | "children" | "validationErrors"
  > {
  redirectTo?: string;
}

export function PreventiveCreateForm({
  redirectTo,
  ...formProps
}: PreventiveCreateFormProps) {
  const formErrors = useActionData() as
    | AriaFormProps["validationErrors"]
    | undefined;
  const isSubmitting = useSubmitting();

  return (
    <Card>
      <FieldSetTitle>Dados gerais</FieldSetTitle>
      <Form
        schema={PreventiveCreateSchema}
        validationErrors={formErrors}
        {...formProps}
      >
        {({ control }) => (
          <>
            <input type="hidden" name="redirectTo" value={redirectTo} />
            <div className="grid grid-cols-4 gap-x-4 gap-y-6 px-1 pb-4">
              <FormTextInput
                control={control}
                className="col-span-4"
                label="Título"
                name="nome"
              />
              <FormNumberInput
                control={control}
                className="col-span-2"
                label="Frequência"
                name="frequencia"
                minValue={0}
              />
              <FormSelectTimeUnitInput
                control={control}
                className="col-span-2"
                name="unidadeTempoId"
              />
              <div className="col-span-4 grid grid-cols-3 gap-4">
                <FormNbrPickerInput control={control} name="nbrId" />
                <FormSelectClientTypeInput
                  control={control}
                  name="tipoClienteId"
                  isReadOnly={!!control._defaultValues.tipoClienteId}
                />
                <FormTextInput
                  control={control}
                  label="Responsável"
                  name="responsavel"
                />
              </div>
              <FormTextInput
                control={control}
                className="col-span-2"
                label="Componente"
                name="componente"
              />
              <FormSelectPreventivePriorityInput
                className="col-span-2"
                label="Nível de obrigatoriedade"
                control={control}
                name="prioridadeId"
              />
              <FormRichTextInput
                control={control}
                className="col-span-4"
                label="Descreva como realizar a manutenção"
                name="descricao"
              />
            </div>
            <Separator />
            <div className="flex justify-between">
              <FormRootError />
              <Button
                className="px-10 py-3"
                type="submit"
                showSpinner={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </>
        )}
      </Form>
    </Card>
  );
}
