import { forwardRef } from "react";

import { S3Image } from ".";

interface ImagePreviewProps {
  onClick?: () => void;
  fileUrl: string;
}
export const ImagePreview = forwardRef<HTMLDivElement, ImagePreviewProps>(
  ({ fileUrl, onClick }, ref) => {
    return (
      <div onClick={onClick} ref={ref}>
        <S3Image
          file={fileUrl}
          className="h-56 w-72 cursor-pointer rounded-md"
          emptyIcon="default"
        />
      </div>
    );
  },
);
