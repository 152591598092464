import { useMemo } from "react";
import { ZodTypeAny, z } from "zod";

import {
  DialogBody,
  DialogButtons,
  DialogHeader,
  FormRootError,
  Modal,
  ModalPageStepButtons,
} from "@/shared/components";
import { usePaginated } from "@/shared/hooks";

import { DialogCore } from "../modals/dialog-core";
import { FormWizardCore, FormWizardCoreProps } from "./form-wizard-core";

export interface ModalFormWizardProps<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined,
> extends Omit<FormWizardCoreProps<TSchema, TSubmitSchema>, "onSubmit"> {
  onSubmit: (
    values: TSubmitSchema extends undefined
      ? z.infer<TSchema>
      : z.infer<TSubmitSchema extends ZodTypeAny ? TSubmitSchema : never>,
    close: () => void,
  ) => void;
}

export function ModalFormWizard<
  TSchema extends ZodTypeAny,
  TSubmitSchema extends ZodTypeAny | undefined,
>({ onSubmit, ...props }: ModalFormWizardProps<TSchema, TSubmitSchema>) {
  const { page: initialPage, steps } = props;
  const { page, isFirstPage, isLastPage, previous, setPage } = usePaginated({
    initialPage,
    pageCount: steps.length,
  });

  const currentStep = useMemo(() => steps[page - 1], [page, steps]);

  return (
    <Modal {...props}>
      <DialogCore role="alertdialog">
        {({ close }) => (
          <FormWizardCore
            {...props}
            page={page}
            onPageChange={setPage}
            onSubmit={(e) => onSubmit(e, close)}
            resetOnSuccess
          >
            {({ step, submitHandler, form: { reset } }) => (
              <>
                <DialogHeader
                  onClose={() => {
                    close();
                    reset();
                    setPage(initialPage ?? 1);
                  }}
                >
                  {currentStep.heading.head}
                </DialogHeader>
                <DialogBody>{step}</DialogBody>
                <DialogButtons>
                  <FormRootError />
                  <ModalPageStepButtons
                    isFirstPage={isFirstPage}
                    isLastPage={isLastPage}
                    onPrevious={previous}
                    onCancel={close}
                    onNext={submitHandler}
                    onSubmit={submitHandler}
                  />
                </DialogButtons>
              </>
            )}
          </FormWizardCore>
        )}
      </DialogCore>
    </Modal>
  );
}
