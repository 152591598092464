import { Card, Separator } from "@/shared/components";
import { Revisao } from "@/shared/services";
import { nullishStringMask, phoneMask } from "@/shared/utils/masks";

interface ResponsibleCardProps {
  preventiveReview?: Revisao;
}
export function ResponsibleCard({ preventiveReview }: ResponsibleCardProps) {
  return (
    <Card className="shadow-header">
      <div className="flex gap-4">
        <span className="text-base font-bold text-black-2">Responsável</span>
      </div>
      <Separator />
      <div className="flex items-center gap-14">
        <div className="flex flex-col gap-2">
          <span className="text-base font-bold text-black-2">
            Nome da empresa
          </span>
          <span className="text-sm font-medium text-primary">
            {preventiveReview?.nome}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-base font-bold text-black-2">
            Responsável pela manutenção
          </span>
          <span className="text-sm font-medium text-primary">
            {preventiveReview?.responsavel}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-base font-bold text-black-2">Email</span>
          <span className="text-sm font-medium text-primary">
            {nullishStringMask(preventiveReview?.responsavelEmail)}
          </span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-base font-bold text-black-2">Telefone </span>
          <span className="text-sm font-medium text-primary">
            {nullishStringMask(
              phoneMask(preventiveReview?.responsavelTelefone ?? undefined),
            )}
          </span>
        </div>
      </div>
    </Card>
  );
}
