import { Link as LinkIcon } from "iconsax-react";

import {
  AnimatedContainer,
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useMutationProductDelete,
  useMutationProductUpdate,
  useProduct,
} from "@/shared/hooks/api";
import { sanitize } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import { ProductDetailError, ProductDetailLoading } from "../components";

export function ProductDetail() {
  const { productId } = useRequiredParams();
  const productQuery = useProduct(productId);
  const mutationProductUpdate = useMutationProductUpdate();
  const mutationProductDelete = useMutationProductDelete();

  return (
    <AnimatedContainer>
      <div className="space-y-4">
        <QueryHandler
          {...productQuery}
          renderLoading={ProductDetailLoading}
          renderError={(error) => <ProductDetailError error={error} />}
        >
          {(data) => (
            <Card className="shadow-header">
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-4">
                  <div className="flex flex-col justify-center">
                    <div className="flex items-center gap-2">
                      <PageTitle primary>{data?.nome}</PageTitle>
                      <Can I="update.product">
                        <EditLink />
                      </Can>
                      <Can I="delete.product">
                        <DeleteResourceButton
                          resource="produto"
                          subject={data?.nome ?? ""}
                          action={() =>
                            mutationProductDelete.mutateAsync({
                              id: +productId,
                            })
                          }
                          showSpinner={mutationProductDelete.isPending}
                        />
                      </Can>
                    </div>
                  </div>
                </div>
                <Can I="update.product">
                  <SwitchStatusButton
                    isDisabled={mutationProductUpdate.isPending}
                    isSelected={data?.flagAtivo}
                    onChange={(status) =>
                      mutationProductUpdate.mutateAsync({
                        id: +productId,
                        requestBody: { flagAtivo: status },
                      })
                    }
                  />
                </Can>
              </div>

              <div className="flex gap-2 text-xs text-primary">
                Ambientes:{" "}
                {data?.memoriais?.map((memorial) => (
                  <span className="font-semibold">{memorial.nome}</span>
                ))}
              </div>

              <Separator />
              <p className="mb-3 text-sm text-black-2">Dados gerais</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
                <div>
                  <p>Marca ou Fabricante</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(data?.fabricante)}
                  </p>
                </div>
                <div>
                  <p>Modelo</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(data?.modelo)}
                  </p>
                </div>

                <div>
                  <p>Site</p>
                  <a
                    className="flex items-center gap-1 font-bold text-info"
                    href={data?.linkManual ?? undefined}
                    target="_blank"
                  >
                    {data?.linkManual && <LinkIcon size="1rem" />}
                    {nullishStringMask(data?.linkManual ?? undefined)}
                  </a>
                </div>
              </div>
              <Separator />
              <div className="space-y-3">
                <p className="mb-3 text-sm text-black-2">Descrição</p>
                <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                  <div
                    className="tiptap text-black-0 [&>p]:min-h-6"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(data?.descricao ?? "Vazio..."),
                    }}
                  />
                </div>
                <p className="mb-3 text-sm text-black-2">Cuidados de uso</p>
                <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                  <div
                    className="tiptap text-black-0 [&>p]:min-h-6"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(data?.cuidados ?? "Vazio..."),
                    }}
                  />
                </div>
                <p className="mb-3 text-sm text-black-2">Perdas de garantia</p>
                <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-sm">
                  <div
                    className="tiptap text-black-0 [&>p]:min-h-6"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(data?.perdas ?? "Vazio..."),
                    }}
                  />
                </div>
              </div>
            </Card>
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
