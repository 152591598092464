import { Handle, Navigate, RouteObject } from "react-router";

import {
  PreventiveConfigLayout,
  PreventiveReviewMaintenance,
  PreventiveReviewService,
} from "./containers";

export const customizationRouter: RouteObject = {
  path: "customizacao",
  handle: {
    tabBaseUrl: "customization",
    /* TODO: Add pending action */
  } as Handle,
  children: [
    { index: true, element: <Navigate to="revisao/" /> },
    {
      element: <PreventiveConfigLayout />,
      children: [
        {
          path: "revisao",
          handle: {
            crumb: "Configurações de revisão",
          } as Handle,
          element: <PreventiveReviewService />,
        },
      ],
    },
    {
      path: "revisao",
      handle: {
        crumb: "Configurações de revisão",
      } as Handle,
      children: [
        {
          path: "manutencoes",
          handle: {
            crumb: "Manutençòes",
          } as Handle,
          element: <PreventiveReviewMaintenance />,
        },
      ],
    },
  ],
};
