import { TickCircle } from "iconsax-react";
import { ModalOverlayProps } from "react-aria-components";

import CheckBackground from "@/assets/check-background.svg?react";
import { Button, Dialog, Modal } from "@/shared/components";
import { useMutationPreventiveReviewApprove } from "@/shared/hooks/api";

export interface PreventiveReviewApproveModalProps
  extends Omit<ModalOverlayProps, "children"> {
  preventiveReviewId: number;
}

export function PreventiveReviewApproveModal({
  preventiveReviewId,
  ...props
}: PreventiveReviewApproveModalProps) {
  const preventiveReviewApproveMutation = useMutationPreventiveReviewApprove({
    onSettled: () => {
      props.onOpenChange?.(false);
    },
  });

  return (
    <Modal isDismissable {...props}>
      <Dialog
        className="w-screen max-w-[40vw]"
        title="Confirme sua intenção de aprovar"
        renderButtons={() => (
          <>
            <Button
              className="px-7 py-3"
              showSpinner={preventiveReviewApproveMutation.isPending}
              onPress={() =>
                preventiveReviewApproveMutation.mutateAsync({
                  id: preventiveReviewId,
                })
              }
            >
              Confirmar
            </Button>
          </>
        )}
      >
        <div className="flex flex-col items-center justify-center gap-10">
          <div className="relative flex w-64 items-center justify-center">
            <TickCircle
              variant="Bold"
              className="absolute z-20 size-14 text-success duration-1000 animate-in fade-in spin-in"
            />
            <CheckBackground className="rotate-12 duration-1000 ease-in-out animate-in direction-alternate repeat-infinite" />
          </div>
          <div className="flex flex-col items-center gap-2">
            <span className="text-2xl font-semibold text-success">
              Aprovar solicitação
            </span>
            <span className="text-center text-base font-medium text-gray-8">
              Você vai aprovar essa solicitação, confirme essa ação abaixo
            </span>
          </div>
        </div>
      </Dialog>
    </Modal>
  );
}
