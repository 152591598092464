import { apiProvider } from "@/shared/services";

import { ComboBoxApiResourceInput, ComboBoxApiResourceInputProps } from ".";

type ServiceFunction = typeof service;

export type ComboBoxServiceStatusInputProps<T extends object> = Omit<
  ComboBoxApiResourceInputProps<
    T,
    Awaited<ReturnType<ServiceFunction>>["content"][number],
    Parameters<ServiceFunction>[0]
  >,
  "service" | "queryKey" | "label" | "mapElementsKey"
>;

const service =
  apiProvider.services.StatusManutencaosService.getObjsStatusManutencaoGet;

export function ComboBoxServiceStatusInput<T extends object>(
  props: ComboBoxServiceStatusInputProps<T>,
) {
  return (
    <ComboBoxApiResourceInput
      label="Status"
      queryKey={["serviceStatus"]}
      service={async ({ pageParam: page }, extraFilters) =>
        await service({
          page,
          elementsPerPage: PAGE_COUNT,
          ...extraFilters,
        })
      }
      mapElementsKey={(e) => ({ id: e.id, text: e.nome })}
      {...props}
    />
  );
}
