import { Cell, DialogTrigger } from "react-aria-components";

import {
  Can,
  ClientTypeFilter,
  ComboBoxCompanyWorkerInput,
  ComboBoxDevelopmentInput,
  ComboBoxServiceStatusInput,
  DetailsCell,
  FiltersAccordion,
  PaginationButtons,
  QueryHandler,
  SearchInput,
  Spinner,
  StatusCell,
  Table,
  TableRow,
  UnauthorizedModal,
} from "@/shared/components";
import { useMaintenances, useProfile } from "@/shared/hooks/api";
import { MaintenanceTypeId } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

export function ListAssistances() {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    useMaintenances({
      text: "",
      tipoManutencaoId: MaintenanceTypeId.ASSISTANCE,
    });
  const profileQuery = useProfile();

  return (
    <div className="mt-0 flex flex-col pt-4">
      <div className="mb-4 flex gap-4">
        <SearchInput className="flex-1" onDebounceChange={handleSearchChange} />
        <DialogTrigger>
          {/* TODO: Add create call modal */}
          <UnauthorizedModal />
        </DialogTrigger>
      </div>
      <FiltersAccordion className="mb-4">
        <ComboBoxDevelopmentInput
          styleType="filter"
          filters={{ assistenciaTecnica: true }}
          onSelectionChange={(development) =>
            updateFilters({
              empreendimentoId: development ? +development : undefined,
            })
          }
        />
        <QueryHandler
          {...profileQuery}
          renderLoading={() => <Spinner className="mx-auto" />}
        >
          {(profile) => (
            <>
              {profile?.construtoraId && (
                <ComboBoxCompanyWorkerInput
                  styleType="filter"
                  developmentId={profile.construtoraId}
                  onSelectionChange={(responsibleId) =>
                    updateFilters({
                      colaboradorResponsavelId: responsibleId
                        ? +responsibleId
                        : undefined,
                    })
                  }
                />
              )}
            </>
          )}
        </QueryHandler>
        <ComboBoxServiceStatusInput
          styleType="filter"
          filters={{ tipoManutencaoId: MaintenanceTypeId.ASSISTANCE }}
          onSelectionChange={(status) =>
            updateFilters({
              statusManutencaoId: status ? +status : undefined,
            })
          }
        />
        <ClientTypeFilter
          showLabel
          onSelectionChange={(v) =>
            updateFilters({
              unidadeTipoClientId: v ? (+v > 0 ? +v : undefined) : undefined,
            })
          }
        />
      </FiltersAccordion>
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0 shadow-header">
        <div className="overflow-y-auto">
          <Can I="get.client">
            {(allowed) => (
              <Table
                aria-label="Lista chamados de assistêcia"
                headers={[
                  "Protocolo",
                  "Solicitante",
                  "Tipo do Cliente",
                  "Empreendimento",
                  "Bloco",
                  "Responsável",
                  "Status",
                ]}
                rowsData={data?.content}
                isLoading={isLoading}
                emptyProps={{
                  resource: "chamado de assistência",
                  search: filters.text ?? undefined,
                }}
                removeDetailsColumn={!allowed}
                renderRow={(data) => (
                  <TableRow
                    key={data.id}
                    href={allowed ? `detalhes/${data.id}/` : undefined}
                  >
                    <Cell>{data.id}</Cell>
                    <Cell>{nullishStringMask(data.solicitante?.nome)}</Cell>
                    <Cell>
                      {nullishStringMask(data.unidade?.tipoCliente?.nome)}
                    </Cell>
                    <Cell>{nullishStringMask(data.empreendimento?.nome)}</Cell>
                    <Cell>{nullishStringMask(data.unidade?.bloco.nome)}</Cell>
                    <Cell>
                      {nullishStringMask(data.colaboradorResponsavel?.nome)}
                    </Cell>
                    <StatusCell
                      status={data.statusManutencao?.nome}
                      color={data.statusManutencao?.cor}
                    />
                    {allowed && <DetailsCell />}
                  </TableRow>
                )}
              />
            )}
          </Can>
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </div>
  );
}
