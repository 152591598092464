import { Card, InfiniteQueryHandler, Separator } from "@/shared/components";
import { useInfinitePreventiveReviewFiles } from "@/shared/hooks/api";

import { FileCard } from "./file-card";

interface DocumentsCardProps {
  preventiveReviewId: number;
}
export function DocumentsCard({ preventiveReviewId }: DocumentsCardProps) {
  const [{ flatData, isFetching, error }, , total, { ref }] =
    useInfinitePreventiveReviewFiles({
      text: "",
      revisaoId: preventiveReviewId,
    });

  const groupedFiles = Object.groupBy(
    flatData ?? [],
    ({ categoriaArquivoId }) => categoriaArquivoId,
  );

  return (
    <Card className="shadow-header">
      <span className="text-base font-bold text-black-2">
        Documentos anexados
      </span>

      <Separator />
      <InfiniteQueryHandler
        isFetching={isFetching}
        error={error}
        isEmpty={!total}
        renderEmpty={() => (
          <div className="p-4 text-center text-sm font-semibold">
            Nenhum dado disponível
          </div>
        )}
      >
        <div className="flex flex-col gap-4">
          {Object.entries(groupedFiles).map(([categoriaId, files]) => (
            <div key={categoriaId}>
              <span className="text-base font-bold text-black-2">
                {files?.[0].categoriaArquivo.nome}
              </span>
              <div className="flex flex-wrap items-center gap-4">
                {files?.map((file, index) => (
                  <FileCard
                    key={file.id}
                    title={file.arquivo}
                    ref={
                      flatData?.length && index === flatData.length - 1
                        ? ref
                        : null
                    }
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </InfiniteQueryHandler>
    </Card>
  );
}
