import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import {
  ApproveRevisaoAprovarIdPutData,
  Revisao,
  apiProvider,
} from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationPreventiveReviewApproveConfig
  extends Omit<
    UseMutationOptions<Revisao, Error, ApproveRevisaoAprovarIdPutData>,
    "mutationFn"
  > {}

export function useMutationPreventiveReviewApprove(
  options?: UseMutationPreventiveReviewApproveConfig,
) {
  return useMutation({
    mutationFn: apiProvider.services.RevisaosService.approveRevisaoAprovarIdPut,
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["preventiveReview", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao aprovar revisão: ${e.message}`,
      }),
    ...options,
  });
}
