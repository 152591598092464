import { Link as LinkIcon } from "iconsax-react";

import {
  AnimatedContainer,
  Breadcrumbs,
  Can,
  Card,
  DeleteResourceButton,
  EditLink,
  PageTitle,
  QueryHandler,
  ResourceProfileImage,
  Separator,
  SwitchStatusButton,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useContact,
  useMutationContactUpdate,
  useMutationDeleteContact,
} from "@/shared/hooks/api";
import { cepMask, nullishStringMask, phoneMask } from "@/shared/utils/masks";

import { ContactDetailError, ContactDetailLoading } from "../components";

export function ContactDetail() {
  const { contactId } = useRequiredParams();
  const contactQuery = useContact(contactId);
  const mutationContactUpdate = useMutationContactUpdate();
  const mutationContactDelete = useMutationDeleteContact();

  return (
    <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
      <div className="space-y-4">
        <Breadcrumbs />
        <PageTitle>Detalhes do contato</PageTitle>
      </div>
      <div className="space-y-4">
        <QueryHandler
          {...contactQuery}
          renderLoading={ContactDetailLoading}
          renderError={(error) => <ContactDetailError error={error} />}
        >
          {(data) => (
            <Card className="shadow-[0_4px_10px_0_rgba(0,0,0,0.08)]">
              <div className="flex items-center gap-4">
                <ResourceProfileImage file={data?.logo} />
                <div className="flex flex-1 flex-col">
                  <div className="flex justify-between gap-2">
                    <div className="flex flex-col justify-center">
                      <div className="flex items-center gap-2">
                        <p className="black-2 break-all font-bold">
                          {data?.nome}
                        </p>
                        <Can I="update.contact">
                          <EditLink />
                        </Can>
                        <Can I="delete.contact">
                          <DeleteResourceButton
                            resource="contato"
                            subject={data?.nome ?? ""}
                            action={() =>
                              mutationContactDelete.mutateAsync({
                                id: +contactId,
                              })
                            }
                            showSpinner={mutationContactDelete.isPending}
                          />
                        </Can>
                      </div>
                    </div>
                    <Can I="update.contact">
                      <SwitchStatusButton
                        isDisabled={mutationContactUpdate.isPending}
                        isSelected={data?.flagAtivo}
                        onChange={(status) =>
                          mutationContactUpdate.mutateAsync({
                            id: +contactId,
                            requestBody: { flagAtivo: status },
                          })
                        }
                      />
                    </Can>
                  </div>
                  <div className="flex gap-6 text-xs text-primary">
                    <p>
                      Tipo:{" "}
                      <span className="font-semibold">
                        {nullishStringMask(data?.tipoContato?.nome)}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <p className="my-4 text-sm">{data?.descricao}</p>
              <Separator />
              <p className="mb-3 text-sm text-black-2">Contato</p>
              <div className="flex gap-11 rounded-2xl border border-primary-light-2 px-8 py-6 text-xs">
                <div>
                  <p>Nome</p>
                  <p className="break-all font-bold text-primary">
                    {data?.nome}
                  </p>
                </div>
                <div>
                  <p>Telefone</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(phoneMask(data?.telefone ?? undefined))}
                  </p>
                </div>
                <div>
                  <p>Email</p>
                  <p className="font-bold text-primary">
                    {nullishStringMask(data?.email ?? undefined)}
                  </p>
                </div>
                <div>
                  <p>Site</p>
                  <a
                    className="flex items-center gap-1 font-bold text-info"
                    href={data?.site ?? undefined}
                    target="_blank"
                  >
                    {data?.site && <LinkIcon size="1rem" />}
                    {nullishStringMask(data?.site ?? undefined)}
                  </a>
                </div>
              </div>
              <Separator />
              <p className="mb-3 text-sm text-black-2">Localização</p>
              <div className="flex items-center justify-between rounded-2xl border border-white-2 px-8 py-6">
                <div className="text-sm text-gray-6">
                  <p>Endereço</p>
                  <p className="font-semibold text-gray-12">
                    {nullishStringMask(
                      [
                        data?.rua,
                        data?.numero?.toString(),
                        cepMask(data?.cep ?? undefined),
                        data?.bairro,
                        [data?.cidade, data?.estado]
                          .filter(Boolean)
                          .join(" - "),
                      ]
                        .filter(Boolean)
                        .join(", "),
                    )}
                  </p>
                </div>
              </div>
            </Card>
          )}
        </QueryHandler>
      </div>
    </AnimatedContainer>
  );
}
