import { frequencyString } from "@/shared/utils";

interface FrequencyTagProps {
  frequency?: number | null;
  timeUnit?: string | null;
}
export function FrequencyTag({ frequency, timeUnit }: FrequencyTagProps) {
  return (
    <div className="rounded bg-primary-light-1 px-3 py-2">
      <span className="flex items-center justify-center text-sm font-semibold text-primary">
        Periodicidade{" "}
        <span className="mx-2 inline-block h-1 w-1 rounded-full bg-current" />
        {frequencyString({ frequency, timeUnit })}
      </span>
    </div>
  );
}
