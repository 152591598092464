import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router";

import {
  AnimatedContainer,
  PageTitle,
  QueryHandler,
  Spinner,
  Tabs,
} from "@/shared/components";
import { RestrictedArea } from "@/shared/components/restricted-area";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";
import { useDevelopments } from "@/shared/hooks/api";

export function PreventiveLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("preventive");
  const [preventiveDevelopmentsQuery] = useDevelopments({
    gestaoDeManutencaoPrevertiva: true,
    elementsPerPage: 1,
  });

  const tabs = useAuthorizedTabs([
    {
      id: `${baseUrl}revisao/`,
      href: `${baseUrl}revisao/`,
      label: "Revisão de manutenção",
      action: "list.maintenance",
    },
    {
      id: `${baseUrl}servico/`,
      href: `${baseUrl}servico/`,
      label: "Serviço de manutenção",
      /* TODO: Add pending action */
    },
    {
      id: `${baseUrl}calendario/`,
      href: `${baseUrl}calendario/`,
      label: "Calendário",
      /* TODO: Add pending action */
    },

    {
      id: `${baseUrl}configuracoes/`,
      href: `${baseUrl}configuracoes/`,
      label: "Configurações",
      /* TODO: Add pending action */
    },
  ]);

  return (
    <QueryHandler
      {...preventiveDevelopmentsQuery}
      renderLoading={() => (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
    >
      {(data) => (
        <RestrictedArea
          title="Acesso bloqueado para gestão de manutenção preventiva"
          text="Módulo de gestão de manutenção preventiva não foi contratado, caso queria aproveitar mais recursos ou isso não foi esperado, entre em contato com a nossa equipe de suporte."
          supportMessage="Quero acessar o módulo de gestão de manutenção preventiva"
          allowAccess={(data?.total ?? 0) > 0}
        >
          <AnimatedContainer className="mt-0 flex h-full w-[calc(100vw-240px)] flex-col space-y-6 overflow-y-auto p-8">
            <div className="space-y-4">
              <PageTitle primary>Preventivos</PageTitle>
            </div>
            <Tabs
              aria-label="Preventivos do sistema"
              tabs={tabs}
              selectedKey={pathname}
            >
              <TabPanel id={pathname}>
                <Outlet />
              </TabPanel>
            </Tabs>
          </AnimatedContainer>
        </RestrictedArea>
      )}
    </QueryHandler>
  );
}
