import { Cell } from "react-aria-components";
import tinycolor from "tinycolor2";

import { cn } from "@/shared/utils";

interface StatusCellProps {
  status?: string | null;
  color?: string;
}

export function StatusCell({ status, color }: StatusCellProps) {
  return (
    <Cell>
      <p
        className={cn(
          "inline-block rounded-lg px-5 py-1.5 text-sm font-semibold capitalize",
        )}
        style={{
          backgroundColor: tinycolor(color).setAlpha(0.2).toRgbString(),
          color,
        }}
      >
        {status ?? "Desconhecido"}
      </p>
    </Cell>
  );
}
