import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ArrowDown2 } from "iconsax-react";
import { forwardRef } from "react";

import { cn } from "@/shared/utils";

import { Separator } from "../separator";
import { AccordionStyleProps, content, root, trigger } from "./style/accordion";

interface AccordionPrimitiveTriggerProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>,
    AccordionStyleProps {}

interface AccordionPrimitiveContentProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>,
    AccordionStyleProps {}

export interface AccordionProps
  extends React.PropsWithChildren,
    AccordionStyleProps {
  className?: string;
  title: string;
  defaultOpen?: boolean;
}
export const Accordion = forwardRef<HTMLDivElement, AccordionProps>(
  ({ className, children, styleType, title, defaultOpen, ...props }, ref) => {
    return (
      <AccordionPrimitive.Root
        className={cn(root(props), className)}
        type="single"
        collapsible
        defaultValue={defaultOpen ? "item-1" : undefined}
        ref={ref}
      >
        <AccordionItem value="item-1">
          <AccordionTrigger styleType={styleType}>{title}</AccordionTrigger>
          <AccordionContent styleType={styleType}>{children}</AccordionContent>
        </AccordionItem>
      </AccordionPrimitive.Root>
    );
  },
);

const AccordionItem = forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn("", className)} {...props} />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionPrimitiveTriggerProps
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(trigger(props), className)}
      {...props}
    >
      {props.styleType === "filter" ? (
        <>
          <div className="flex items-center gap-1">
            <ArrowDown2
              className="duration-20 h-4 w-4 shrink-0 transition-transform"
              variant="Bold"
            />
            {children}
          </div>
          <Separator className="m-0 border-gray-1" />
        </>
      ) : (
        <div className="flex w-full items-center justify-between">
          {children}
          <ArrowDown2
            className="duration-20 shrink-0 transition-transform"
            variant="Linear"
            size={"1.5rem"}
          />
        </div>
      )}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  AccordionPrimitiveContentProps
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn(content(props), className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;
