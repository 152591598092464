import { AxiosError } from "axios";
import { redirect } from "react-router";

import { capitalizeString } from ".";
import { toastQueue } from "../components/toast";
import { ApiError, apiProvider } from "../services";

interface handleErrorProps {
  error: unknown;
  requestUrl?: string;
}
export function handleApiError({ error, requestUrl }: handleErrorProps) {
  if (error instanceof ApiError) {
    if (error.status >= 400 && error.status < 500) {
      if (error.status === 401) {
        toastQueue.add(
          { type: "error", message: "Sessão expirada" },
          {
            timeout: 5000,
          },
        );

        apiProvider.removeAuthToken();
        if (requestUrl) {
          return redirect(`/login?from=${new URL(requestUrl).pathname}`);
        }
      }

      const validationErrors: ValidationErrorResponse = (
        error.body as { errors: ValidationErrorResponse }
      ).errors;

      if (validationErrors.length === 0) {
        toastQueue.add({
          type: "error",
          message: "Erro ao enviar, cheque novamente",
        });
      } else {
        for (const { message } of validationErrors) {
          toastQueue.add({
            type: "error",
            message: capitalizeString(message),
          });
        }
      }

      return validationErrors.reduce(
        (acc, fieldError) => ({
          ...acc,
          [fieldError.field]: fieldError.message,
        }),
        {},
      );
    }

    if (error.status >= 500) {
      return toastQueue.add(
        { type: "error", message: "Erro interno no servidor" },
        {
          timeout: 5000,
        },
      );
    }
  }

  console.error(error);
  if (error instanceof AxiosError && error.code === "ERR_NETWORK") {
    return toastQueue.add(
      { type: "error", message: "Servidor fora do ar" },
      {
        timeout: 5000,
      },
    );
  }

  return toastQueue.add(
    { type: "error", message: "Erro desconhecido" },
    {
      timeout: 5000,
    },
  );
}
