import { UseMutationOptions, useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import {
  RefuseRevisaoRecusarIdPutData,
  Revisao,
  apiProvider,
} from "@/shared/services";
import { queryClient } from "@/shared/utils";

export interface UseMutationPreventiveReviewRejectConfig
  extends Omit<
    UseMutationOptions<Revisao, Error, RefuseRevisaoRecusarIdPutData>,
    "mutationFn"
  > {}

export function useMutationPreventiveReviewReject(
  options?: UseMutationPreventiveReviewRejectConfig,
) {
  return useMutation({
    mutationFn: apiProvider.services.RevisaosService.refuseRevisaoRecusarIdPut,
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["preventiveReview", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao rejeitar revisão: ${e.message}`,
      }),
    ...options,
  });
}
