import { zodResolver } from "@hookform/resolvers/zod";
import { Cell } from "react-aria-components";
import { useForm } from "react-hook-form";

import {
  Can,
  DetailsCell,
  FiltersAccordion,
  FormComboBoxBlockInput,
  FormComboBoxDevelopmentInput,
  FormComboBoxManualInput,
  FormComboBoxProductInput,
  FormComboBoxSubsystemInput,
  FormComboBoxUnitTypeInput,
  PaginationButtons,
  SearchInput,
  StatusCell,
  StatusReviewFilter,
  Table,
  TableRow,
} from "@/shared/components";
import { usePreventiveReviews } from "@/shared/hooks/api";
import { displayDate } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import { PreventiveFilter, PreventiveFilterSchema } from "../schema";

export function ListPreventiveReview() {
  const [{ data, isLoading }, { filters, updateFilters, handleSearchChange }] =
    usePreventiveReviews({
      text: "",
      statusRevisaoId: 2,
    });

  const { control, resetField, reset } = useForm<PreventiveFilter>({
    resolver: zodResolver(PreventiveFilterSchema),
  });

  return (
    <div className="mt-0 flex flex-col pt-4">
      <div className="mb-4 flex gap-4">
        <SearchInput className="flex-1" onDebounceChange={handleSearchChange} />
      </div>
      <form>
        <FiltersAccordion className="mb-4">
          <FormComboBoxDevelopmentInput
            styleType="filter"
            control={control}
            name="empreendimentoId"
            filters={{ gestaoDeManutencaoPrevertiva: true }}
            onSelectionChange={(development) => {
              updateFilters({
                empreendimentoId: development ? +development : undefined,
              });
              reset({
                empreendimentoId: development ? +development : undefined,
              });
            }}
          />
          <FormComboBoxBlockInput
            styleType="filter"
            control={control}
            name="blocoId"
            isDisabled={!filters.empreendimentoId}
            filters={{ empreendimentoId: filters.empreendimentoId }}
            onSelectionChange={(block) => {
              updateFilters({
                unidadeBlocoId: block ? +block : undefined,
              });
              resetField("unidadeId");
            }}
          />
          <FormComboBoxUnitTypeInput
            styleType="filter"
            control={control}
            name="unidadeId"
            isDisabled={!filters.unidadeBlocoId}
            filters={{ blocoId: filters.unidadeBlocoId }}
            onSelectionChange={(unit) =>
              updateFilters({
                unidadeId: unit ? +unit : undefined,
              })
            }
          />
          <FormComboBoxManualInput
            control={control}
            name="manualId"
            styleType="filter"
            isDisabled={!filters.empreendimentoId}
            filters={{ empreendimentoId: filters.empreendimentoId }}
            onSelectionChange={(manual) => {
              updateFilters({
                manualId: manual ? +manual : undefined,
              });
              resetField("subsistemaId");
              resetField("produtoId");
            }}
          />
          <FormComboBoxSubsystemInput
            control={control}
            name="subsistemaId"
            styleType="filter"
            isDisabled={!filters.manualId}
            filters={{ sistemaManualId: filters.manualId }}
            onSelectionChange={(subsystem) => {
              updateFilters({
                preventivoSubsistemaId: subsystem ? +subsystem : undefined,
              });
              resetField("produtoId");
            }}
          />
          <FormComboBoxProductInput
            control={control}
            name="produtoId"
            styleType="filter"
            isDisabled={!filters.preventivoSubsistemaId || !filters.manualId}
            filters={{
              subsistemaId: filters.preventivoSubsistemaId,
              subsistemaSistemaManualId: filters.manualId,
            }}
            onSelectionChange={(product) =>
              updateFilters({
                preventivoProdutoId: product ? +product : undefined,
              })
            }
          />
          <StatusReviewFilter
            showLabel
            defaultSelectedKey={2}
            onSelectionChange={(v) =>
              updateFilters({
                statusRevisaoId: v ? (+v > 0 ? +v : undefined) : undefined,
              })
            }
          />
        </FiltersAccordion>
      </form>
      <div className="flex flex-col overflow-y-auto rounded-lg bg-white-0 shadow-header">
        <div className="overflow-y-auto">
          <Can I="get.client">
            {(allowed) => (
              <Table
                aria-label="Lista chamados de assistêcia"
                headers={[
                  "Protocolo",
                  "Solicitante",
                  "Empreendimento",
                  "Manutenção",
                  "Data de registro",
                  "Status",
                ]}
                rowsData={data?.content}
                isLoading={isLoading}
                emptyProps={{
                  resource: "revisão de manutenção",
                  search: filters.text ?? undefined,
                }}
                removeDetailsColumn={!allowed}
                renderRow={(data) => (
                  <TableRow
                    key={data.id}
                    href={allowed ? `detalhes/${data.id}/` : undefined}
                  >
                    <Cell>{data.id}</Cell>
                    <Cell>{nullishStringMask(data.solicitante.nome)}</Cell>
                    <Cell>{nullishStringMask(data.empreendimento?.nome)}</Cell>
                    <Cell>{nullishStringMask(data.preventivo.nome)}</Cell>
                    <Cell>{displayDate(data.criadoEm)}</Cell>
                    <StatusCell
                      status={data.statusRevisao?.nome}
                      color={data.statusRevisao?.cor}
                    />
                    {allowed && <DetailsCell />}
                  </TableRow>
                )}
              />
            )}
          </Can>
        </div>
        <PaginationButtons
          currentPage={filters.page ?? 1}
          pageCount={Math.ceil((data?.total ?? 0) / PAGE_COUNT)}
          onPageChange={(page) => updateFilters({ page })}
        />
      </div>
    </div>
  );
}
