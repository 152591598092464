import { Handle, RouteObject } from "react-router";

import { customizationRouter } from "./modules/customization/router";

export const configRouter: RouteObject = {
  path: "configuracoes",
  handle: {
    crumb: "Configurações",
    /* TODO: Add pending action */
  } as Handle,
  children: [customizationRouter],
};
