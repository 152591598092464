import { useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

export function useMutationCompanyReviewStatus() {
  return useMutation({
    mutationFn:
      apiProvider.services.ConstrutorasService
        .changePreventivoConfigConstrutorasIdConfigPreventivoRevisavelPut,
    onSettled: async (_, __, { id }) => {
      await queryClient.invalidateQueries({
        queryKey: ["company", id.toString()],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao editar construtora: ${e.message}`,
      }),
  });
}
