import { Calendar } from "iconsax-react";
import { useState } from "react";
import { DialogTrigger } from "react-aria-components";

import {
  Button,
  Card,
  ImagePreview,
  InfiniteQueryHandler,
  Separator,
  Slider,
  SliderContent,
  VideoPreview,
} from "@/shared/components";
import { useInfinitePreventiveReviewFiles } from "@/shared/hooks/api";
import { Revisao } from "@/shared/services";
import { PreventiveReviewStatusId, displayDate } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";
import { isImage } from "@/shared/utils/validators";

import { StatusHistoryModal, StatusInfoCard } from ".";

interface DetailCardProps {
  preventiveReview?: Revisao;
}
export function DetailCard({ preventiveReview }: DetailCardProps) {
  const [{ flatData, isFetching, error }, , total, { ref }] =
    useInfinitePreventiveReviewFiles({
      text: "",
      revisaoId: preventiveReview?.id,
    });

  const [carouselOpen, setCarouselOpen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const mediaFiles = flatData?.filter(
    (file) => file.categoriaArquivo.nome === "Foto/Video",
  );

  const solicitations = preventiveReview?.solicitacoes ?? [];
  const lastSolicitation = solicitations.at(-1);
  const lastSolicitationIndex = solicitations.length;

  return (
    <Card className="shadow-header">
      <span className="text-base font-bold text-black-2">
        Detalhamento da manutenção
      </span>
      <Separator />
      <div className="flex flex-col gap-4">
        {preventiveReview?.statusRevisaoId ===
          PreventiveReviewStatusId.SOLICITATION && (
          <div className="flex flex-col gap-3">
            <StatusInfoCard
              statusRevisao={preventiveReview.statusRevisao}
              solicitation={lastSolicitation}
              solicitationIndex={lastSolicitationIndex}
            />
            <DialogTrigger>
              <Button
                className="justify-start p-0 text-xs font-semibold text-primary outline-none"
                styleType="default"
              >
                Ver histórico de solicitações
              </Button>
              <StatusHistoryModal preventiveReview={preventiveReview} />
            </DialogTrigger>
          </div>
        )}

        <div className="flex gap-12">
          <div className="flex flex-col gap-2">
            <span className="text-base font-bold text-black-2">
              Data da manutenção
            </span>
            <div className="flex items-center gap-2">
              <Calendar variant="Bold" className="text-primary" />
              <span className="text-sm font-normal text-black-0">
                {nullishStringMask(
                  displayDate(preventiveReview?.occurrenceDate),
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-base font-bold text-black-2">
            Resumo da manutenção
          </span>
          <div className="flex items-center gap-2">
            <span className="text-sm font-normal text-black-0">
              {nullishStringMask(preventiveReview?.observacao)}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-base font-bold text-black-2">
            Imagens e vídeos anexados
          </span>
          <InfiniteQueryHandler
            isFetching={isFetching}
            error={error}
            isEmpty={!total}
            renderEmpty={() => (
              <div className="p-4 text-center text-sm font-semibold">
                Nenhum dado disponível
              </div>
            )}
          >
            <div className="flex flex-wrap items-center gap-4">
              {mediaFiles?.map((file, index) =>
                isImage(file.arquivo) ? (
                  <ImagePreview
                    key={file.id}
                    fileUrl={file.arquivo}
                    onClick={() => {
                      setCarouselOpen(true);
                      setCarouselIndex(index);
                    }}
                    ref={index === mediaFiles.length - 1 ? ref : null}
                  />
                ) : (
                  <VideoPreview
                    key={file.id}
                    fileUrl={file.arquivo}
                    onClick={() => {
                      setCarouselOpen(true);
                      setCarouselIndex(index);
                    }}
                    ref={index === mediaFiles.length - 1 ? ref : null}
                  />
                ),
              )}

              <Slider
                total={mediaFiles?.length}
                isOpen={carouselOpen}
                onClose={() => setCarouselOpen(false)}
                selectedIndex={carouselIndex}
                setCarouselIndex={setCarouselIndex}
              >
                {mediaFiles?.map((file) => (
                  <SliderContent fileUrl={file.arquivo} key={file.id} />
                ))}
              </Slider>
            </div>
          </InfiniteQueryHandler>
        </div>
      </div>
    </Card>
  );
}
