import { showNewMessage } from "@intercom/messenger-js-sdk";
import { Lock1 } from "iconsax-react";

import { Button } from "./button/button";
import { Card } from "./card";

export interface RestrictedAreaProps {
  title: string;
  text: string;
  supportMessage: string;
  allowAccess?: boolean;
  children?: React.ReactNode;
}

export function RestrictedArea({
  title,
  text,
  supportMessage,
  children,
  allowAccess,
}: RestrictedAreaProps) {
  if (allowAccess) {
    return children;
  }

  return (
    <div className="relative h-full w-full">
      <div className="absolute z-50 flex h-full w-full items-center justify-center bg-black-0/25 p-8 backdrop-blur">
        <Card className="flex max-w-96 flex-col items-center gap-4">
          <Lock1 className="size-32 text-primary" variant="Bold" />
          <h4 className="text-center text-xl font-bold">{title}</h4>
          <p>{text}</p>
          <Button
            className="px-4 py-3"
            onPress={() => showNewMessage(supportMessage)}
          >
            Entrar em contato
          </Button>
        </Card>
      </div>
      {children}
    </div>
  );
}
