import { TabPanel } from "react-aria-components";
import { Outlet, useLocation } from "react-router";

import {
  AnimatedContainer,
  PageTitle,
  QueryHandler,
  Spinner,
  Tabs,
} from "@/shared/components";
import { RestrictedArea } from "@/shared/components/restricted-area";
import { useAuthorizedTabs, useTabBaseUrl } from "@/shared/hooks";
import { useDevelopments } from "@/shared/hooks/api";

export function AssistanceLayout() {
  const { pathname } = useLocation();
  const baseUrl = useTabBaseUrl("assistance");
  const [assistanceDevelopmentsQuery] = useDevelopments({
    assistenciaTecnica: true,
    elementsPerPage: 1,
  });

  const tabs = useAuthorizedTabs([
    {
      id: `${baseUrl}kanban/`,
      href: `${baseUrl}kanban/`,
      label: "Kanban",
      action: "list.maintenance",
    },
    {
      id: `${baseUrl}lista/`,
      href: `${baseUrl}lista/`,
      label: "Lista",
      action: "list.maintenance",
    },
    {
      id: `${baseUrl}agendamentos/`,
      href: `${baseUrl}agendamentos/`,
      label: "Agendamentos",
      /* TODO: Add pending action */
    },
    {
      id: `${baseUrl}duvidas/`,
      href: `${baseUrl}duvidas/`,
      label: "Dúvidas",
      /* TODO: Add pending action */
    },
    {
      id: `${baseUrl}configuracoes/`,
      href: `${baseUrl}configuracoes/`,
      label: "Configurações",
      /* TODO: Add pending action */
    },
  ]);

  return (
    <QueryHandler
      {...assistanceDevelopmentsQuery}
      renderLoading={() => (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
    >
      {(data) => (
        <RestrictedArea
          title="Acesso bloqueado para assistência técnica"
          text="Módulo de assistência técnica não foi contratado, caso queria aproveitar mais recursos ou isso não foi esperado, entre em contato com a nossa equipe de suporte."
          supportMessage="Quero acessar o módulo de assistência técnica"
          allowAccess={(data?.total ?? 0) > 0}
        >
          <AnimatedContainer className="mt-0 flex h-full w-[calc(100vw-240px)] flex-col space-y-6 overflow-y-auto p-8">
            <div className="space-y-4">
              <PageTitle primary>Assistência técnica</PageTitle>
            </div>
            <Tabs
              aria-label="Assistência técnica do sistema"
              tabs={tabs}
              selectedKey={pathname}
            >
              <TabPanel id={pathname}>
                <Outlet />
              </TabPanel>
            </Tabs>
          </AnimatedContainer>
        </RestrictedArea>
      )}
    </QueryHandler>
  );
}
