export enum MaintenanceTypeId {
  PREVENTIVE = 1,
  ASSISTANCE = 2,
}

export enum MaintenanceStatusId {
  SOLICITED = 1,
  IN_PROGRESS = 2,
  SCHEDULED = 3,
  SERVICE_IN_PROGRESS = 4,
  COMPLETED = 5,
  REJECTED = 6,
}

export enum AssistanceStatus {
  UNDER_REVIEW = 7,
  IN_PROGRESS = 8,
  INSPECION_SCHEDULED = 9,
  OPEN = 10,
  NEW_INSPECTION_SCHEDULED = 11,
  WAITING_FOR_REVIEW = 12,
  COMPLETED = 13,
  REJECTED = 14,
  CANCELED = 15,
}

export enum ChannelTypeId {
  CLIENT = 1,
  ADMIN = 2,
}
