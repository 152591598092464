import { type RouteObject, redirect } from "react-router";

import { assistanceRouter } from "@/modules/assistance/router";
import { preventiveRouter } from "@/modules/preventive/router";
import { protectedLoader } from "@/shared/utils/router";

import { ErrorPage, PrivateLayout, PrivateMenuLayout } from "../layout";
import { apiProvider } from "../services";
import { RedirectUser } from "./redirect-user";
import {
  clientsRouter,
  constructionCompanyRouter,
  constructionCompanyTabsRouter,
  faqRouter,
  profileRouter,
  recordRouter,
  usersRouter,
  workerDevelopmentRouter,
} from "./router-modules";

const privateRouter: RouteObject[] = [
  {
    id: "auth",
    path: "/",
    element: <PrivateLayout />,
    loader: protectedLoader,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "app",
        element: <PrivateMenuLayout />,
        children: [
          {
            index: true,
            element: <RedirectUser />,
          },
          constructionCompanyTabsRouter,
          constructionCompanyRouter,
          clientsRouter,
          faqRouter,
          recordRouter,
          workerDevelopmentRouter,
          assistanceRouter,
          preventiveRouter,
          {
            path: "logout",
            async action() {
              await apiProvider.signout();
              return redirect("/");
            },
          },
        ],
      },
      profileRouter,
      usersRouter,
    ],
  },
];

export default privateRouter;
