import { VariantProps, tv } from "tailwind-variants";

export const container = tv({
  base: "flex flex-col text-sm",
  variants: {
    styleType: {
      default: "gap-2",
      filter: "gap-0",
    },
  },
  defaultVariants: {
    styleType: "default",
  },
});

export const label = tv({
  variants: {
    styleType: {
      default: "font-semibold text-gray-12",
      filter: "text-gray-6",
    },
  },
  defaultVariants: {
    styleType: "default",
  },
});

export const input = tv({
  base: "border",
  variants: {
    styleType: {
      default: [
        "rounded-xl border-transparent bg-white-2 px-4 py-3",
        "rac-focus:border rac-focus:border-primary rac-focus:outline rac-focus:outline-[3px] rac-focus:outline-primary-light-2",
      ],
      filter: "rounded-lg border-white-3 bg-white-0 p-3",
    },
    isDisabled: {
      true: "bg-white-2 text-gray-4",
    },
  },
  defaultVariants: {
    styleType: "default",
  },
});

export const arrow = tv({
  base: "text-gray-9 transition-all peer-rac-hover:translate-y-1",
  variants: {
    isDisabled: {
      true: "bg-white-2 text-gray-4",
    },
  },
});

export type InputStyleProps = VariantProps<typeof container>;
