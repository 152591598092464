import { ModalOverlayProps } from "react-aria-components";

import { Dialog, Modal } from "@/shared/components";
import { Revisao } from "@/shared/services";

import { StatusInfoCard } from "./status-info-card";

export interface StatusHistoryModalProps
  extends Omit<ModalOverlayProps, "children"> {
  preventiveReview: Revisao;
}
export function StatusHistoryModal({
  preventiveReview,
  ...props
}: StatusHistoryModalProps) {
  return (
    <Modal {...props}>
      <Dialog
        className="w-screen max-w-[60vw]"
        title="Histórico de solicitações de informações"
      >
        <div className="flex flex-col gap-2.5">
          {preventiveReview.solicitacoes?.map((solicitation, index) => (
            <StatusInfoCard
              key={solicitation.id}
              rejectionReason={preventiveReview.reprovacaoJustificativa}
              solicitation={solicitation}
              solicitationIndex={index + 1}
              statusRevisao={preventiveReview.statusRevisao}
            />
          ))}
        </div>
      </Dialog>
    </Modal>
  );
}
