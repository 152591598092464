import {
  AnimatedContainer,
  ApprovalHeader,
  Breadcrumbs,
  PageTitle,
  QueryHandler,
  Spinner,
} from "@/shared/components";
import { useRequiredParams, useSwitch } from "@/shared/hooks";
import { usePreventiveReview } from "@/shared/hooks/api";
import { PreventiveReviewStatusId } from "@/shared/utils";

import {
  DetailCard,
  DocumentsCard,
  GeneralDataCard,
  PreventiveReviewApproveModal,
  PreventiveReviewRejectModal,
  PreventiveReviewRequestInfoModal,
  ResponsibleCard,
} from "../components";

export function DetailPreventiveReview() {
  const { preventiveId } = useRequiredParams();

  const {
    isOn: isApprovalOn,
    turnOn: approvalTurnOn,
    setOpen: approvalSetOpen,
  } = useSwitch(false);
  const {
    isOn: isRejectOn,
    turnOn: rejectTurnOn,
    setOpen: rejectSetOpen,
  } = useSwitch(false);
  const {
    isOn: isRequetInfoOn,
    turnOn: requestInfoTurnOn,
    setOpen: requestInfoSetOpen,
  } = useSwitch(false);

  const preventiveReviewQuery = usePreventiveReview(preventiveId);

  return (
    <QueryHandler
      {...preventiveReviewQuery}
      renderLoading={() => (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      )}
    >
      {(preventive) => (
        <AnimatedContainer className="mt-0 flex h-full flex-col space-y-5 overflow-y-auto p-8">
          <div className="space-y-4">
            <Breadcrumbs />
            <PageTitle primary>Detalhes da solicitação de revisão</PageTitle>
          </div>
          <div>
            <div className="space-y-4">
              {preventive?.statusRevisaoId ===
                PreventiveReviewStatusId.PENDING && (
                <>
                  <ApprovalHeader
                    title="Deseja aprovar essa solicitação?"
                    turnApproveModalOn={approvalTurnOn}
                    turnRejectModalOn={rejectTurnOn}
                    turnRequestInfoModalOn={requestInfoTurnOn}
                  />
                  <PreventiveReviewApproveModal
                    preventiveReviewId={+preventiveId}
                    isOpen={isApprovalOn}
                    onOpenChange={approvalSetOpen}
                  />
                  <PreventiveReviewRejectModal
                    preventiveReviewId={+preventiveId}
                    isOpen={isRejectOn}
                    onOpenChange={rejectSetOpen}
                  />
                  <PreventiveReviewRequestInfoModal
                    preventiveReviewId={+preventiveId}
                    isOpen={isRequetInfoOn}
                    onOpenChange={requestInfoSetOpen}
                  />
                </>
              )}
              <GeneralDataCard preventiveReview={preventive} />
              <DetailCard preventiveReview={preventive} />
              <ResponsibleCard preventiveReview={preventive} />
              <DocumentsCard preventiveReviewId={+preventiveId} />
            </div>
          </div>
        </AnimatedContainer>
      )}
    </QueryHandler>
  );
}
