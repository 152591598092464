import { Add } from "iconsax-react";

import {
  AnimatedContainer,
  Button,
  Card,
  QueryHandler,
  Separator,
} from "@/shared/components";
import { useRequiredParams, useSwitch } from "@/shared/hooks";
import { useMaintenance } from "@/shared/hooks/api";

import {
  FileListContainer,
  FileListError,
  FileListLoading,
  MediaFilesCreateModal,
} from "../components";

export function MediaFilesList() {
  //TODO: change param to callId
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId);

  const { isOn, turnOn, setOpen } = useSwitch(false);

  return (
    <QueryHandler
      {...maintenanceQuery}
      renderLoading={FileListLoading}
      renderError={(error) => <FileListError error={error} />}
    >
      {(maintenance) => {
        const companyFiles = maintenance?.manutencaoArquivo?.filter(
          (file) => file.daConstrutora,
        );
        const clientFiles = maintenance?.manutencaoArquivo?.filter(
          (file) => !file.daConstrutora,
        );

        return (
          <AnimatedContainer className="mt-0">
            <Card className="shadow-header">
              <div className="flex items-center gap-4">
                <span className="text-base font-bold text-black-2">
                  Arquivos e mídia
                </span>
                <Button
                  styleType="default"
                  className="flex gap-2 py-1 text-base font-bold"
                  onPress={turnOn}
                >
                  <Add size={24} /> Adicionar mídia
                </Button>
              </div>
              <Separator />

              {!!companyFiles?.length && (
                <>
                  <span className="text-sm font-semibold text-gray-10">
                    Construtora
                  </span>
                  <FileListContainer files={companyFiles} />
                </>
              )}
              {!!clientFiles?.length && (
                <>
                  <span className="text-sm font-semibold text-gray-10">
                    Cliente
                  </span>
                  <FileListContainer files={clientFiles} />
                </>
              )}
              {maintenance && (
                <MediaFilesCreateModal
                  manutencaoId={maintenance.id}
                  isOpen={isOn}
                  onOpenChange={setOpen}
                />
              )}
            </Card>
          </AnimatedContainer>
        );
      }}
    </QueryHandler>
  );
}
