import { Handle, RouteObject } from "react-router";

import { DetailPreventiveReview } from "./modules/detail/containers";

export const preventiveReviewDetailRouter: RouteObject = {
  path: "revisao",
  handle: {
    crumb: "Revisão",
  } as Handle,
  children: [
    {
      path: "detalhes/:preventiveId",
      handle: {
        crumb: "Detalhes da solicitação",
        action: "get.preventiveReview",
      } as Handle,
      element: <DetailPreventiveReview />,
    },
  ],
};
