import { useParams } from "react-router";

import {
  AnimatedContainer,
  Breadcrumbs,
  ErrorContainer,
  PageTitle,
  Spinner,
} from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import { useFailures, useWarranty } from "@/shared/hooks/api";
import { ApiError, FalhaWithUnidadeTempo } from "@/shared/services";

import { WarrantyEditForm } from "../components";

export function WarrantyEdit() {
  const { productId, subsystemId } = useParams();
  const { warrantyId } = useRequiredParams();

  const { data, error, isLoading, isError } = useWarranty(warrantyId);
  const [
    {
      data: failuresData,
      isLoading: isFailureLoading,
      isError: isFailureError,
    },
    ,
  ] = useFailures({
    garantiaId: +warrantyId,
  });

  const mapFailures = (failures?: FalhaWithUnidadeTempo[]) =>
    failures?.map((falha) => ({
      nome: falha.nome,
      prazo: falha.prazo,
      unidadeTempoId: falha.unidadeTempoId,
    }));

  const defaultValues = productId
    ? {
        produtoId: +productId,
        nome: data?.nome,
        notas: data?.notas,
        falhas: mapFailures(failuresData?.content),
      }
    : {
        subsistemaId: subsystemId ? +subsystemId : undefined,
        nome: data?.nome,
        notas: data?.notas,
        falhas: mapFailures(failuresData?.content),
      };

  function renderLoading() {
    return <Spinner className="mx-auto" />;
  }

  function renderError() {
    return (
      <ErrorContainer
        message={
          error instanceof ApiError && error.status === 404
            ? `Dados não encontrados...`
            : undefined
        }
      />
    );
  }

  return (
    <AnimatedContainer className="m-0 flex h-full flex-col overflow-y-auto">
      <div className="flex-1 space-y-4 overflow-y-auto p-8">
        <Breadcrumbs />
        <PageTitle primary>Editar garantia</PageTitle>
        {isLoading || isFailureLoading ? (
          renderLoading()
        ) : isError || isFailureError ? (
          renderError()
        ) : (
          <WarrantyEditForm
            redirectTo="../"
            useFormProps={{
              defaultValues,
            }}
          />
        )}
      </div>
    </AnimatedContainer>
  );
}
