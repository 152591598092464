import { QueryHandler, Separator, Spinner } from "@/shared/components";
import { useRequiredParams } from "@/shared/hooks";
import {
  useFailure,
  useMaintenance,
  useMemorial,
  useProduct,
  useSubsystem,
  useSystem,
} from "@/shared/hooks/api";
import { nullishStringMask } from "@/shared/utils/masks";

import {
  MaintenanceDetailError,
  MaintenanceDetailLoading,
} from "../../../components";
import { FileListContainer } from "../../media-files/components";

export function SolicitationData() {
  const { serviceId } = useRequiredParams();
  const maintenanceQuery = useMaintenance(serviceId, {
    refetchOnMount: false,
  });

  const productQuery = useProduct(
    maintenanceQuery.data?.produtoId
      ? maintenanceQuery.data.produtoId.toString()
      : "",
  );

  const failureQuery = useFailure(
    maintenanceQuery.data?.falhaId
      ? maintenanceQuery.data.falhaId.toString()
      : "",
  );

  const memorialQuery = useMemorial(
    maintenanceQuery.data?.memorialId
      ? maintenanceQuery.data.memorialId.toString()
      : "",
  );

  const systemQuery = useSystem(
    maintenanceQuery.data?.sistemaId
      ? maintenanceQuery.data.sistemaId.toString()
      : "",
  );

  const subsystemQuery = useSubsystem(
    maintenanceQuery.data?.subsistemaId
      ? maintenanceQuery.data.subsistemaId.toString()
      : "",
  );

  return (
    <QueryHandler
      {...maintenanceQuery}
      renderLoading={MaintenanceDetailLoading}
      renderError={(error) => <MaintenanceDetailError error={error} />}
    >
      {(maintenance) => {
        const companyFiles = maintenance?.manutencaoArquivo?.filter(
          (file) => file.daConstrutora,
        );
        const clientFiles = maintenance?.manutencaoArquivo?.filter(
          (file) => !file.daConstrutora,
        );
        return (
          <div>
            <p className="tb-3 pb-4 text-sm font-bold text-gray-12">
              Informações da assistência
            </p>
            <div className="mb-2 grid grid-cols-3 gap-2">
              <QueryHandler
                {...memorialQuery}
                renderLoading={() => (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                )}
                renderError={(error) => (
                  <MaintenanceDetailError error={error} />
                )}
              >
                {(memorial) => (
                  <p className="rounded bg-purple-10 py-2 text-center text-sm text-purple">
                    Local:{" "}
                    <span className="font-bold">
                      {nullishStringMask(memorial?.nome)}
                    </span>
                  </p>
                )}
              </QueryHandler>
              <QueryHandler
                {...systemQuery}
                renderLoading={() => (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                )}
                renderError={(error) => (
                  <MaintenanceDetailError error={error} />
                )}
              >
                {(system) => (
                  <p className="rounded bg-primary-light-1 py-2 text-center text-sm text-primary">
                    Sistema:{" "}
                    <span className="font-bold">
                      {nullishStringMask(system?.nome)}
                    </span>
                  </p>
                )}
              </QueryHandler>
              <QueryHandler
                {...subsystemQuery}
                renderLoading={() => (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                )}
                renderError={(error) => (
                  <MaintenanceDetailError error={error} />
                )}
              >
                {(subsystem) => (
                  <p className="rounded bg-primary-light-1 py-2 text-center text-sm text-primary">
                    Subsistema:{" "}
                    <span className="font-bold">
                      {nullishStringMask(subsystem?.nome)}
                    </span>
                  </p>
                )}
              </QueryHandler>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <QueryHandler
                {...productQuery}
                renderLoading={() => (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                )}
                renderError={(error) => (
                  <MaintenanceDetailError error={error} />
                )}
              >
                {(product) => (
                  <p className="rounded bg-[#FA75601A] py-2 text-center text-sm text-[#FA7560]">
                    Produto:{" "}
                    <span className="font-bold">
                      {nullishStringMask(product?.nome)}
                    </span>
                  </p>
                )}
              </QueryHandler>
              <QueryHandler
                {...failureQuery}
                renderLoading={() => (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                )}
                renderError={(error) => (
                  <MaintenanceDetailError error={error} />
                )}
              >
                {(failure) => (
                  <p className="rounded bg-[#2D9CDB33] py-2 text-center text-sm text-[#2D9CDB]">
                    Falha:{" "}
                    <span className="font-bold">
                      {nullishStringMask(failure?.nome)}
                    </span>
                  </p>
                )}
              </QueryHandler>
            </div>
            <Separator />
            <p className="tb-3 mb-3 text-sm font-bold text-gray-12">
              Descrição geral
            </p>
            <p className="text-sm text-typography-2">
              {nullishStringMask(maintenance?.resumo)}
            </p>
            {maintenance?.manutencaoArquivo &&
              maintenance.manutencaoArquivo.length > 0 && (
                <>
                  <Separator />
                  <p className="tb-3 mb-3 text-sm font-bold text-gray-12">
                    Arquivos
                  </p>
                  {!!companyFiles?.length && (
                    <>
                      <span className="text-sm font-semibold text-gray-10">
                        Construtora
                      </span>
                      <FileListContainer files={companyFiles} />
                    </>
                  )}
                  {!!clientFiles?.length && (
                    <>
                      <span className="text-sm font-semibold text-gray-10">
                        Cliente
                      </span>
                      <FileListContainer files={clientFiles} />
                    </>
                  )}
                </>
              )}
          </div>
        );
      }}
    </QueryHandler>
  );
}
