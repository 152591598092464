import { Setting2 } from "iconsax-react";

import { AnimatedContainer, Card, FieldSetTitle } from "@/shared/components";

import { PreventiveConfigItem } from "../components/preventive-config-item";

export function PreventiveConfig() {
  return (
    <AnimatedContainer>
      <Card className="flex flex-col gap-5 px-6 py-4">
        <FieldSetTitle className="m-0">Configurações do sistema</FieldSetTitle>
        <div className="flex flex-col gap-3">
          <PreventiveConfigItem
            icon={<Setting2 className="size-6 text-primary" variant="Bold" />}
            label="Customização"
            href="./customizacao"
          />
        </div>
      </Card>
    </AnimatedContainer>
  );
}
