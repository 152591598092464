import tinycolor from "tinycolor2";

import { Card, Separator } from "@/shared/components";
import { Revisao } from "@/shared/services";
import { PreventiveReviewStatusId, sanitize } from "@/shared/utils";
import { nullishStringMask } from "@/shared/utils/masks";

import { FrequencyTag, StatusInfoCard } from ".";

interface GeneralDataCardProps {
  preventiveReview?: Revisao;
}
export function GeneralDataCard({ preventiveReview }: GeneralDataCardProps) {
  return (
    <Card className="relative gap-4 rounded-none rounded-t-lg border-none p-4">
      <div
        className="absolute left-0 top-0 h-full w-1 rounded-l-lg"
        style={{ backgroundColor: preventiveReview?.statusRevisao.cor }}
      />
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <span
            className="text-xl font-bold text-gray-8"
            style={{ color: preventiveReview?.statusRevisao.cor }}
          >
            #{preventiveReview?.id} - {preventiveReview?.preventivo.nome}
          </span>
          <div className="w-fit rounded-md bg-info px-3 py-1 text-xs font-semibold text-white-0">
            {preventiveReview?.preventivo.prioridade.nome}
          </div>
        </div>
        <div
          className="rounded-md bg-gray-1 px-8 py-2.5"
          style={{
            backgroundColor: tinycolor(preventiveReview?.statusRevisao.cor)
              .setAlpha(0.2)
              .toRgbString(),
          }}
        >
          <span
            className="text-sm font-bold"
            style={{ color: preventiveReview?.statusRevisao.cor }}
          >
            {preventiveReview?.statusRevisao.nome}
          </span>
        </div>
      </div>
      <Separator />
      <div className="flex flex-col gap-5">
        <div className="flex gap-2 py-4 text-xs xl:gap-11">
          <div>
            <p>Solicitante</p>
            <p className="font-bold text-primary">
              {nullishStringMask(preventiveReview?.solicitante.nome)}
            </p>
          </div>
          <div>
            <p>Tipo do cliente</p>
            <p className="font-bold text-primary">
              {nullishStringMask(preventiveReview?.manual.tipoCliente?.nome)}
            </p>
          </div>
          <div>
            <p>Empreendimento</p>
            <p className="font-bold text-primary">
              {nullishStringMask(preventiveReview?.empreendimento?.nome)}
            </p>
          </div>
          <div>
            <p>Unidade</p>
            <p className="font-bold text-primary">
              {preventiveReview?.unidade.nome}
            </p>
          </div>
        </div>
        {preventiveReview?.statusRevisaoId ===
          PreventiveReviewStatusId.REJECTED && (
          <StatusInfoCard
            statusRevisao={preventiveReview.statusRevisao}
            rejectionReason={preventiveReview.reprovacaoJustificativa}
          />
        )}
      </div>
      <Separator />

      <FrequencyTag
        frequency={preventiveReview?.preventivo.frequencia}
        timeUnit={preventiveReview?.preventivo.unidadeTempo.nome}
      />

      <div className="mt-5 flex flex-col gap-2">
        <p className="text-base font-bold text-black-2">
          Como realizar a manutenção preventiva
        </p>
        <p
          className="text-sm font-medium text-black-2"
          dangerouslySetInnerHTML={{
            __html: sanitize(preventiveReview?.preventivo.descricao ?? "Vazio"),
          }}
        ></p>
      </div>
    </Card>
  );
}
