import { ArrowRight2 } from "iconsax-react";
import { MenuTrigger } from "react-aria-components";

import {
  Button,
  Menu,
  MenuItem,
  QueryHandler,
  Separator,
  Spinner,
} from "@/shared/components";
import {
  useMaintenancesStatus,
  useMutatioMaintenanceUpdate,
} from "@/shared/hooks/api";
import { StatusManutencao } from "@/shared/services";
import { capitalizeString } from "@/shared/utils";

interface StatusMenuProps {
  maintenanceStatus?: StatusManutencao | null;
  maintenanceId: number;
}

export function StatusMenu({
  maintenanceStatus,
  maintenanceId,
}: StatusMenuProps) {
  const [maintenanceStatusQuery] = useMaintenancesStatus({
    tipoManutencaoId: maintenanceStatus?.tipoManutencaoId,
    elementsPerPage: undefined,
  });

  const callUpdateMutation = useMutatioMaintenanceUpdate();

  return (
    <QueryHandler {...maintenanceStatusQuery} renderLoading={() => <Spinner />}>
      {(data) => (
        <MenuTrigger>
          <Button
            style={{ backgroundColor: maintenanceStatus?.cor }}
            className="cursor-pointer rounded px-4 py-3"
            styleType="menu"
            showSpinner={callUpdateMutation.isPending}
          >
            {capitalizeString(
              data?.content.find(
                (status) => status.id === maintenanceStatus?.id,
              )?.nome,
            ) || "Desconhecido"}
            <Separator orientation="vertical" className="ml-5 mr-2" />
            <ArrowRight2 size="12" color="white" />
          </Button>
          <Menu>
            {data?.content.map((status, index) => (
              <MenuItem
                isDisabled={status.id === maintenanceStatus?.id}
                key={index}
                onAction={() =>
                  callUpdateMutation.mutateAsync({
                    id: maintenanceId,
                    requestBody: {
                      statusManutencaoId: status.id,
                    },
                  })
                }
              >
                {capitalizeString(status.nome)}
              </MenuItem>
            ))}
          </Menu>
        </MenuTrigger>
      )}
    </QueryHandler>
  );
}
