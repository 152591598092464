import { useMutation } from "@tanstack/react-query";

import { toastQueue } from "@/shared/components/toast";
import { apiProvider } from "@/shared/services";
import { queryClient } from "@/shared/utils";

const service =
  apiProvider.services.PreventivosService.updateAnalyzePreventivoIdAnalisePut;

export type AnalyzeReviewUpdatePayload = Parameters<typeof service>[0];

export function useMutationAnalyzeReviewUpdate() {
  return useMutation({
    mutationFn: (payload: AnalyzeReviewUpdatePayload) => {
      return service(payload);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["preventives"],
      });
    },
    onError: (e) =>
      toastQueue.add({
        type: "error",
        message: `Falha ao editar preventivo: ${e.message}`,
      }),
  });
}
